.expert {
    height: 100vh !important;
    width: 100% !important;
    box-shadow: none !important;
}

.expert .wrapper {
    position: absolute;
    left: 4px;
    right: 4px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    /* display: flex; */
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: flex-end;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    bottom: 3px;
    border-radius: 9px;
}


.expert .content {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 12px 16px;
    color: #fff;
    overflow: hidden;
    padding-right: 0;
    position: absolute;
    bottom: 0;
}

.expert .mainWrap {
    position: relative;
    padding: 3px;
    height: 148;
    width: 148;
    box-shadow: none !important;
}

.sub-content {
    font-weight: bold;
}

.img {
    border-radius: 9px;
}

.css-pdwytc-MuiPaper-root {
    border: 4px solid #fff;
    outline: 2px solid black;

    /* border-image-source: linear-gradient(180deg, #ef2a4c 0%, #6c318a 100%); */
}

.expert .onbaording-selected-image {
    border-radius: 6px;
    margin: 0px;
    padding: 2px;
    background: linear-gradient(180deg, #ef2a4c 0%, #6c318a 100%);

}

.expert .MuiAppBar-colorPrimary {
    color: black;
    background-color: #fff;
}

.expert .MuiPaper-elevation4 {
    box-shadow: none !important;
}

.expert .wrapper .check {
    position: absolute;
    z-index: 1111;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.expert .wrapper .check-hide {
    display: none;
}

.expert .MuiToolbar-gutters {
    padding-left: 1px !important;
    padding-right: 20px !important;
}

.expert .my-headings {
font-family: 'Montserrat' !important;
font-style: normal !important;
font-weight: 600 !important;
font-size: 48px !important;
line-height: 72px;
letter-spacing: -1px;
color: #11142D !important;
}

.expert .wrapper .check-circle-icon {
    font-size: 40px !important;
    color: #fff !important;
}

.expert .arrow-icon {
    color: #1E1F20;
}

.expert .skip-btn {
font-family: 'Montserrat';
font-style: normal !important;
font-weight: 500 !important;
font-size: 20px !important;
line-height: 24px;
color: #292A34 !important;
text-transform: capitalize !important;
}

.expert .proceed-content {
    justify-content: center !important;
    width: 300px !important;
}

.expert .proceed-content .proceed-btn-disabled {
    border-radius: 20px !important;
    background: #D7DBE5 !important;
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    text-align: center;
    color: #000000 !important;
    text-transform: capitalize !important;
}

.expert .proceed-content .proceed-btn {
    border-radius: 20px !important;
    background: #70308A !important;
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    text-align: center;
    color: #FCFCFD !important;
    text-transform: capitalize !important;
}

@media only screen and (max-width:1200px) {
    .expert .tiles-container {
        justify-content: start !important;
    }
}

@media only screen and (max-width:600px) {
    .expert .tiles-container {
        justify-content: center !important;
    }
}
@media only screen and (max-width:960px) {
    .expert .tiles-container {
        justify-content: center !important;
    }
}
.expert .custom-card {
    max-width: 100%;
    height: 400px;
    margin-bottom: 2px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    border-radius: 20px !important;
}

.expert .custom-card .title {
    text-align: center;
    font-size: 1.2rem !important;
    font-weight: bold !important;
    color: rgb(40, 41, 41);
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.expert .custom-card .sub-title {
    font-size: 0.8rem !important;
    text-align: center;
    color: #686E6C !important;
    font-weight: 500 !important;
}

.expert .custom-card .sub-text {
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 0.8rem !important;
    text-align: center;
    color: #686E6C !important;
    font-weight: 500 !important;
}
.expert .card-wrapper {
position: relative;
padding: 3px 3px 0px 3px;
height: 390px !important;
}
/* .expert .selected-image {
border-radius: 20px;
margin: 0px;
padding: 2px 2px 1px 2px;
background: linear-gradient(180deg, #ef2a4c 0%, #6c318a 100%);
} */
.expert .selected-image {
box-sizing: border-box;
margin: auto;
position: relative;
background: linear-gradient(180deg, #ef2a4c 0%, #6c318a 100%);
background-clip: border-box;
padding: 2px 2px 43.4px 2px;
border-radius: 20px;
border: 1px solid transparent;
background-clip: padding-box;
bottom: 0;
max-width: 100%;
height: 418px;
}


.expert .card-media {
border-top-left-radius: 20px;
border-top-right-radius: 20px;
}
.expert .wrapper .check-circle-icon {
    font-size: 75px !important;
    color: #6c318a !important;
}
.expert .pl-45 {
    padding-left: 45px;
}
.expert .no-selected-image {
    box-sizing: border-box;
    margin: auto;
    position: relative;
    background-clip: border-box;
    background-clip: border-box;
    padding: 2px 2px 0.4px 2px;
    border-radius: 20px;
    border: 1px solid transparent;
    background-clip: padding-box;
    bottom: 0;
    max-width: 100%;
    height: 440px;
}
.expert .no-selected-image2 {
    box-sizing: border-box;
    margin: auto;
    position: relative;
    background-clip: border-box;
    background-clip: border-box;
    padding: 2px 2px 0.4px 2px;
    border-radius: 20px;
    border: 1px solid transparent;
    background-clip: padding-box;
    bottom: 0;
    max-width: 100%;
    height: 424px;
}

@media only screen and (min-width:960px) {
    .expert .MuiGrid-grid-md-3 {
        flex-grow: 0;
        /* max-width: 20% !important; */
        flex-basis: 25%;
    } 
}
@media only screen and (min-width:600px) {
    .expert .MuiGrid-grid-md-3 {
        flex-grow: 0;
        /* max-width: 90% !important; */
        flex-basis: 25%;
    } 
}
@media only screen and (min-width:1200px) {
    .expert .MuiGrid-grid-md-3 {
        flex-grow: 0;
        /* max-width: 16.5% !important; */
        flex-basis: 25%;
    }   
}
.expert .MuiFormControl-fullWidth {
    width: 300px !important;
}
.expert .selected-image2 {
        /* padding: 2px 2px 8px 2px; */
        padding: 3px 5px 7px 5px;
        border: 2px solid transparent;
        background: white;
        border-radius: 18px;
        height: 411px;
}
.expert .MuiInputAdornment-root {
font-family: 'Montserrat';
font-style: normal;
font-weight: 501;
font-size: 14px;
line-height: 24px;
color: #777E90;
}

.expert .MuiInputBase-input::placeholder {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 501 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    color: #777E90 !important;
}