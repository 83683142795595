.user-invite .label-reward {
  margin: 8px;
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #1a1a1a;
}

.user-invite .create-user-btn {
  background: #653889 !important;
  border-radius: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  height: 60px !important;
  width: 175px !important;
}

.user-invite .send-invite-user-btn {
  color: #653889 !important;
  border-radius: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  background: #ffffff !important;
  text-transform: capitalize !important;
  height: 60px !important;
  width: 190px !important;
  border: 2px solid #653889 !important;
}

.user-invite .MuiOutlinedInput-notchedOutline {
  border: 1px solid #d6d6d6 !important;
  border-radius: 15px !important;
  /* background: #FAFAFA !important; */
}

.user-invite .bulk-action {
  width: 100% !important;
}

.user-invite .mainContainer {
  background: #fafafa;
  border: 1px solid #e7e7e7;
  border-radius: 20px;
  padding: 30px;
  overflow-x: auto;
}

.user-invite .tableContainer {
  border-collapse: separate;
  border-spacing: 0 15px;
  width: 100%;
}

.user-invite .tableContainer th {
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #101010;
}

.user-invite th {
  text-align: center;
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
  padding: 5px;
  color: #101010;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.user-invite td {
  text-align: center;
  background: #ffffff;
  border-width: 1px 0px;
  border-style: solid;
  border-color: #979797;
  box-shadow: -6px -6px 25px rgba(230, 230, 230, 0.15),
    6px 6px 25px rgba(229, 229, 229, 0.15);
  padding: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #101010;
  height: 50px;
}

.user-invite td:last-child {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #979797;
  border-left-color: #97979700;
}

.user-invite td:first-child {
  border: 0.5px solid #979797;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-right-color: #97979700;
}

.user-invite .select-filter {
  width: 100% !important;
}

.user-invite .select-filter .MuiSelect-selectMenu {
  border-right: 1px solid #e7e7e7 !important;
}

.user-invite .select-filter .MuiInputBase-input {
  height: unset !important;
}

.user-invite .search .MuiInputBase-input {
  height: unset !important;
}

.user-invite .search .MuiSvgIcon-root {
  font-size: 1.9rem !important;
}

.user-invite .input-label {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  color: #2b2e3c !important;
}

.user-invite .user-heading {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 33px !important;
  color: #12142b !important;
}

.user-invite .custom-chip {
  background: #6c328b !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #fafafa !important;
}

.user-invite .payment-btn {
  background: #6c328b !important;
  border-radius: 12px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #ffffff !important;
  height: 50px !important;
  width: 180px !important;
  text-transform: capitalize !important;
}

.user-invite .download-btn {
  border: 1px solid #6c328b !important;
  background: #ffffff !important;
  border-radius: 12px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #6c328b !important;
  height: 50px !important;
  width: 180px !important;
  text-transform: capitalize !important;
}

.user-invite .MuiChip-label {
  color: #ffffff !important;
}

.custom-dialog .MuiListItemText-primary {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  color: #6c328b !important;
}

.custom-dialog .add-product-btn {
  background: #653889 !important;
  border-radius: 18px !important;
  width: 180px !important;
  height: 50px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  margin-right: 25px !important;
}

.custom-dialog .cancel-btn {
  background: #ffffff !important;
  border: 2px solid #6c328b !important;
  border-radius: 18px !important;
  width: 100px !important;
  height: 50px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #6c328b !important;
  text-transform: capitalize !important;
}

.custom-dialog .dashed-border {
  background: #ffffff !important;
  border: 1px dashed #6c328e !important;
  border-radius: 20px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.custom-dialog .dashed-border .drag-text {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #12142b !important;
  text-transform: capitalize;
}

.custom-dialog .outer-border {
  border: 2px solid rgba(108, 50, 142, 0.25) !important;
  transform: rotate(270deg) !important;
}

.custom-dialog .dashed-border .bottom-text {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #6c328e !important;
}

.custom-dialog .csv-text {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #6c328e !important;
}

.custom-dialog .dashed-border .drag-text2 {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #12142b !important;
  text-transform: capitalize;
  display: flex;
}

.custom-dialog .select-filter {
  width: 100% !important;
}

.custom-dialog .select-all {
  width: 100% !important;
}

.custom-dialog .MuiPaper-root {
  background: #ffffff !important;
  box-shadow: -6px -6px 25px rgba(230, 230, 230, 0.25),
    6px 6px 25px rgba(229, 229, 229, 0.25) !important;
  border-radius: 20px !important;
}

.custom-dialog .MuiOutlinedInput-notchedOutline {
  border: 1px solid #d6d6d6 !important;
  border-radius: 15px !important;
}

.custom-dialog .MuiIconButton-root {
  border-radius: 25% !important;
}

.custom-dialog .add-list {
  background: #6c328b !important;
  height: 30px !important;
  width: 30px !important;
  color: #ffffff !important;
}

.custom-dialog .input-label {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  color: #2b2e3c !important;
}

.custom-dialog .input-label-heading {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  color: #2b2e3c !important;
}

.custom-dialog .MuiListItem-root {
  background: #ffffff !important;
  border-radius: 15px !important;
  box-shadow: 2px 5px 2px 2px rgba(230, 230, 230, 0.25),
    1px 2px 1px 12px rgba(230, 230, 230, 0.25),
    1px 2px 4px 2px rgba(0, 0, 0, 0.12) !important;
  margin-bottom: 15px !important;
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.custom-dialog .MuiList-padding {
  padding-top: 12px !important;
  padding-bottom: 12px !;
}

.custom-dialog .MuiListItemIcon-root {
  border-right: 2px solid #e7e7e7 !important;
  border-radius: 15px !important;
  width: 70px !important;
  height: 50px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-top: 2px solid #e7e7e7 !important;
  border-left: 2px solid #e7e7e7 !important;
  border-bottom: 2px solid #e7e7e7 !important;
  color: #dcdcdc !important;
  margin-right: 20px !important;
}

.custom-dialog .user-heading {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 33px !important;
  color: #12142b !important;
}

.custom-dialog .saturday {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #000000 !important;
}

.custom-dialog .selected-date {
  background: #6c328b !important;
  border-radius: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #fafafa !important;
}

.react-calendar__tile--active {
  background: #6c328b !important;
  border-radius: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #fafafa !important;
}
