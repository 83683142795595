.player-wrapper {
  position: relative;
  padding-top: 56.25%
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.dialog_libraryPersonle {
    max-width: 204px;
    box-shadow: rgb(0 0 0 / 45%) 0px 3px 10px;
    border-radius: 10px;
    margin-top: 9%;
    margin-left: 65%;
    max-height: 65%;
}

.rangeInput {
  width: 100%;
}

.rangeInput .MuiSlider-track {
  height: 10px;
  border-radius: 15px;
  background: linear-gradient(223.1deg, #6C328B 0%, #EF2B4B 93.2%);
}

.rangeInput .MuiSlider-rail {
  height: 10px;
  border-radius: 15px;
  background: #9E9E9E;
}

.rangeInput .MuiSlider-thumb::after{
  position: unset;
}

.rangeInput .MuiSlider-thumb {
  background: linear-gradient(223.1deg, #6C328B 0%, #EF2B4B 93.2%);
  margin-top: -3px;
  width: 16px;
  height: 16px;
}

.numberStyles {
  padding: 10px 15px;
  border-radius: 12px;
  margin-right: 15px;
  border: 1px solid rgba(225, 225, 225, 0.577997);
  color: #6C328B;
  font-weight: 700;
  box-shadow: 0px 0px 16px rgba(213, 213, 213, 0.350581);
}

.videoPlayActiv {
  cursor: pointer;
  border: 1px solid #6c328b;
  box-shadow: 0px 0px 11px #6c328b, 0px 0px 0px #6c328b;
}

.videoPlayList:hover {
  cursor: pointer;
  border: 1px solid #6c328b;
  box-shadow: 0px 0px 11px #6c328b, 0px 0px 0px #6c328b;
}

.cardStyle {
  border-radius: 13px;
  background: #e0e0e0;
  box-shadow: 7px 7px 13px #bebebe, -7px -7px 13px #ffffff;
}

.cardStyle1 {
  border-radius: 16px;
  background: #e0e0e0;
  box-shadow: 11px 11px 22px #bababa,
    -11px -11px 22px #ffffff;
}


.imageView {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.image2View {
  border: 1px solid green;
  margin-left: 10px;
  position: absolute;
  margin-top: -75px;
}

.next {
  background-color: #1c1a1a;
  width: 10px;
  color: white;
}

.round {
  border-radius: 50%;
}

.latest slick-slide {
  width: auto !important;
  margin: -1px;
}

.my-carousel {
  width: 100%;
}

.slider1 .slick-track {
  margin-left: 0px;
}

.slider1 .slick-prev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 50px;
  border-radius: 30px;
  height: 50px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: #11142d;
  border: none;
  outline: none;
  background: #11142d;
  z-index: 500;
}

.slider1 .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 50px;
  border-radius: 30px;
  height: 50px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: #11142d;
  border: none;
  outline: none;
  background: #11142d;
}

.slider1 .slick-next:hover {
  background: #11142d;
}

.slider1 .slick-prev:hover {
  background: #11142d;
}

.container {
  position: relative;
  width: 100%;
  max-width: 300px;
  z-index: -1;
}

.image {
  display: block !important;
  width: 100% !important;
  height: 243px !important;
  border-radius: 25px 25px 0 0 !important;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  padding: 76px;
  text-align: center;
  border-radius: 25px 25px 0 0 !important;
}

.container .overlay {
  opacity: 1;
}

.container1 {
  position: relative;
  width: 100%;
  max-width: 300px;
  z-index: -1;
  padding: 0 15px 0 15px;
}

.image1 {
  display: block !important;
  width: 100% !important;
  height: 243px !important;
  border-radius: 25px 25px 0 0 !important;
}

.overlay1 {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  min-Width: 90%;
  transition: .5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  padding: 0 76px 76px 76px;
  border-radius: 25px 25px 25px 25px !important;
  padding-top: 166px;
}

.container1 .overlay1 {
  opacity: 1;
}

.BarChart {
  height: 100%;
  width: 100%;
}

.inputRounded .MuiOutlinedInput-input {
  padding: 18.5px 32px;
}

.Rounded .MuiOutlinedInput-input {
  padding: 18.5px 45px;
}

.Rounded .MuiOutlinedInput-root {
  border-radius: 10px;
  height: 45px;
}

.inputRounded .MuiOutlinedInput-root {
  border-radius: 10px;
}

.dialog .MuiDialog-paperWidthSm {
  max-width: 204px;
  margin-left: 65%;
  margin-top: 15%;
  max-height: 65%;
  box-shadow: rgb(0 0 0 / 45%) 0px 3px 10px;
  border-radius: 10px;
}


.dialog_library .MuiDialog-paperWidthSm {
  max-width: 204px;
  box-shadow: rgb(0 0 0 / 45%) 0px 3px 10px;
  border-radius: 10px;
  margin-top: 16%;
  margin-left: -10%;
  max-height: 65%;
}

.dialogSort .MuiDialog-paperWidthSm {
  max-width: 204px;
  margin-left: 80%;
  margin-top: 14%;
  box-shadow: rgb(0 0 0 / 45%) 0px 3px 10px;
  border-radius: 10px;
}

.dialogSort_library .MuiDialog-paperWidthSm {
  max-width: 204px;
  margin-left: 10%;
  margin-top: 16%;
  box-shadow: rgb(0 0 0 / 45%) 0px 3px 10px;
  border-radius: 10px;
}

.react-quill .quill {
  height: 140px !important;
}

.react-quill .ql-formats {
  float: right;
  display: flex;
  flex-direction: row-reverse;
}

.react-quill .ql-container {
  height: 140px !important;
}

.react-quill .ql-toolbar.ql-snow {
  border-radius: 15px 15px 0 0;
}

.react-quill .ql-container.ql-snow {
  border: 1px solid #ccc;
  border-radius: 0 0 15px 15px;
}

.react-quill {
  margin: 10px 0;
  border-radius: 10px;
}

.react-quill .ql-editor {
  height: 140px !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .paper {
    width: 85vw;
  }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .paper {
    width: 85vw;
  }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .paper {
    width: 85vw;
  }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .paper {
    width: 85vw;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .paper {
    width: 90vw;
  }
}


.TextInput input {
  height: 145px;
}