.register .login-route{
     width: 100%;
 }

 .register .login-route .MuiPaper-elevation1{
  float: right;
  box-shadow: none;
  font-family: 'Montserrat', sans-serif;
}
.register .login-route  .text{font-family: 'Montserrat';font-style: normal;font-weight: 700;font-size: 14px;color: #23262F;cursor: pointer !important;background: none !important;text-decoration: none !important;}

.register .form {margin-top: 10px;}

.register .form .uiTypography-root .MuiTypography-h3{font-weight: bold;  font-family: 'Montserrat'; text-align: start;}

.register .regform{justify-content: center;}

.register .MuiTypography-h5 { font-size: 32px !important; font-weight: 700 !important;color: #23262F !important;font-style: normal !important;}

.register .MuiTypography-h6 { font-size: 14px !important; font-weight: 400;color: #777E90 !important; font-style: normal !important;}

.register .MuiFormLabel-root {font-size: 12px !important; font-family: 'Montserrat' !important;font-weight: 501 !important;color: #959595 !important;font-style: normal !important;}

.register .MuiInputBase-input {height: 0.5em !important;}

.register .MuiOutlinedInput-root {border-radius: 12px !important;}

.register .MuiInputLabel-root {margin-bottom: 8px;margin-top: 5px;}

.register .MuiTypography-body1 {font-size: 12px !important;}

.register .MuiButton-root {border-radius: 24px !important;background-image: linear-gradient(270deg, #6C328B 0%, #F42074 44.66%, #EF2B4B 100%)!important;font-family: 'Montserrat' !important;font-style: normal !important;font-weight: 700 !important;font-size: 16px !important;color: #FCFCFD !important;text-transform: capitalize !important;}

.register .MuiInputBase-input {font-size: 13px !important;}

.register .MuiInputBase-input:focus {font-size: 13px !important;}

.register .MuiSvgIcon-root {font-size: 0.9rem !important;}

.register .typo { width: 100% !important; margin-top: 46px !important; margin-bottom: 26px !important;align-self: end;}

.pr .MuiOutlinedInput-input { padding: 18.5px 53px 18.5px 14px !important;}

.invalid-feedback {width: 100%; margin-top: 0.25rem; font-size: 80%; color: #dc3545;}

.register #filled-adornment-password-helper-text { max-width: 200;}

.register .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {border-color: rgba(230, 232, 236, 1);}

.register .MuiOutlinedInput-notchedOutline {border:2px solid rgba(230, 232, 236, 1) !important;}

.register .MuiInputBase-input:hover {border-color:rgba(230, 232, 236, 1) !important;}

.register .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline { border-color: #f44336 !important;}

.register .MuiFormControlLabel-root .MuiFormControlLabel-label {font-family: 'Montserrat';font-style: normal;font-weight: 400 !important;font-size: 14px !important;line-height: 24px;color: #777E90;}

.register .MuiInputBase-input::placeholder {font-family: 'Montserrat' !important;font-style: normal !important;font-weight: 501 !important;font-size: 14px !important;color: #777e90!important;}
.register .terms {
  font-size: 14px !important;
  cursor: pointer;
  font-family: 'Montserrat' !important;
  font-weight: 500 !important;
  color: #6C328B !important;
  font-style: normal !important;
  margin-left: 24px;
  background: none !important;
  text-decoration: none !important;
}
