.download-btn {
    border: 1px solid #6C328B !important;
    background: #FFFFFF !important;
    border-radius: 12px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 16px !important;
    text-align: center !important;
    color: #6C328B !important;
    height: 50px !important;
    width: 180px !important;
    text-transform: capitalize !important;
}