.childTab .PrivateTabIndicator-colorSecondary-68 {
  background-color: rgb(239, 233, 233);
}

.childTab1 .Mui-selected {
  background-color: inherit;
}

.inputRounded .MuiOutlinedInput-root {
  border-radius: 20px;
}

.list .MuiInputBase-root  .MuiSelect-icon { top: 3px !important; } 

.list .MuiInputBase-input {height: 1em !important;}

.list .search-outline .search .MuiOutlinedInput-root {max-width: 440px !important;}

.list .MuiInputLabel-root {margin-bottom: 8px;margin-top: 5px;}

.list .MuiInputBase-input {font-size: 13px !important;border: 1px solid #fff !important}

.list .MuiInputBase-input:focus {font-size: 13px !important;}

.list .MuiInputBase-input:hover {border-color:#fff !important;}

.list .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {border-color: #DEDEDE !important;}

.list .MuiOutlinedInput-notchedOutline {border:2px solid #DEDEDE !important;}

.list .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline { border-color: #f44336 !important;}

.list .MuiInputAdornment-positionStart .MuiSvgIcon-root {fill: #DEDEDE !important; font-size: 2.0rem !important;}

.list .course .MuiOutlinedInput-root{border-radius: 0 !important;}

.list  .filter-label .MuiTypography-colorTextSecondary {font-style: normal !important;font-weight: 600 !important;font-size: 13px !important;line-height: 18px !important;letter-spacing: 0.325px !important;color: #58606A !important}

.list .course-btn {background: #6C328B !important;border-radius: 16px !important;height: 56px !important; width: 196px;font-style: normal;font-weight: 600 !important;font-size: 16px !important;line-height: 16px !important;text-align: center !important;color: #FFFFFF !important;text-transform: capitalize !important;margin-left: 30px !important;}

.list .search-outline .MuiOutlinedInput-notchedOutline{border-radius: 18px; border:2px solid #DEDEDE !important;}

.list .box{display:flex;width: 100%;justify-content: flex-end;align-items: center;}

.list .box .select-course .MuiInputBase-input {font-style: normal;font-weight: 600 !important;font-size: 13px !important;line-height: 18px !important;letter-spacing: 0.325px !important;color: #122239 !important;}

.mainHeder_home {
  width: 100%;
  height: 100px;
  box-shadow: 0px 3px 16px rgba(191, 190, 190, 0.350581);
  background-color: #fff;
  padding: 30px;
  border-radius: 15px;
}
.accordion_header {
  width: 160px;
  box-shadow: none;
}

.header_Box {
  background-color: #f3f0f0;
  border-radius: 10px;
  width: 50%;
}

.active {
  background: #fff;
}
.raisedTab {
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}
.noneActive {
  background: #f3f0f0;
}