.table .MuiSelect-icon{
    top: 3px !important;
}

.list .select-course .MuiInputBase-root .MuiSelect-icon{
    display: flex;
    margin-top: 12px;
}

.vertical-line {
    border-right: 1px solid #000; 
    height: 24px; 
    margin-right: 10px; 
  }

.list .search-outline .search .MuiOutlinedInput-root {
    max-width: 440px !important;
}

.list .MuiInputLabel-root {
    margin-bottom: 8px;
    margin-top: 5px;
}

.list .MuiInputBase-input {
    font-size: 13px !important;
    border: 1px solid #fff !important;
    height: 1em !important;

}

.list .MuiInputBase-input:focus {
    font-size: 13px !important;
} 

.list .MuiInputBase-input:hover {
    border-color: #fff !important;
}

.list .MuiTableSortLabel-iconDirectionAsc {
    transform: rotate(180deg);
    display: none;  
}

.list .MuiTableSortLabel-iconDirectionDesc {
    transform: rotate(0deg);
    display: none;
}

.list .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #DEDEDE !important;
}

.list .MuiOutlinedInput-notchedOutline {
    border: 2px solid #DEDEDE !important;
}

.list .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #f44336 !important;
}

.list .MuiInputAdornment-positionStart .MuiSvgIcon-root {
    fill: #DEDEDE !important;
    font-size: 2.0rem !important;
}

.list .course .MuiOutlinedInput-root {
    border-radius: 0 !important;
}

.list .filter-label .MuiTypography-colorTextSecondary {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 13px !important;
    line-height: 18px !important;
    letter-spacing: 0.325px !important;
    color: #58606A !important;
}

.list .course-btn {
    background: #6C328B !important;
    border-radius: 16px !important;
    height: 56px !important;
    width: 196px;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 16px !important;
    text-align: center !important;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
    margin-left: 30px !important;
}

.list .search-outline .MuiOutlinedInput-notchedOutline {
    border-radius: 18px;
    border: 2px solid #DEDEDE !important;
}

.list .box {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}

.list .box .select-course .MuiInputBase-input {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 13px !important;
    line-height: 18px !important;
    letter-spacing: 0.325px !important;
    color: #122239 !important;
}

.list .MuiMenu-paper {
    margin-top: 210px !important
}

.dailog_btn {
    margin: 32px;
    width: 450px;
    position: relative;
    /* overflow-y: auto; */
    height: 85px;
    padding: 14px;
}