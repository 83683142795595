.login .login-route {
  width: 100%;
}

.login .mt-10 {
  margin-top: 10%;
}

.login .login-route .MuiPaper-elevation1 {
  float: right;
  box-shadow: none;
}

.login .login-route .text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #23262F;
  cursor: pointer !important;
  background: none !important;
  text-decoration: none !important;
}

.login .form {
  margin-top: 10px;
}

.login .form .uiTypography-root .MuiTypography-h3 {
  font-weight: bold;
  text-align: start;
}

.login .regform {
  justify-content: center;
}

.login .MuiTypography-h5 {
  font-size: 32px !important;
  font-weight: 700 !important;
  color: #23262F !important;
  font-style: normal !important;
}

.login .MuiTypography-h6 {
  font-size: 14px !important;
  font-weight: 400;
  color: #777E90 !important;
  font-style: normal !important;
}

.login .MuiFormLabel-root {
  font-size: 12px !important;
  font-weight: 501 !important;
  color: #777e90 !important;
  font-style: normal !important;
}

.login .MuiInputBase-input {
  height: 0.5em !important;
}

.login .MuiOutlinedInput-root {
  border-radius: 12px !important;
}

.login .MuiInputLabel-root {
  font-size: 12px !important;
  font-weight: 501 !important;
  color: #777e90 !important;
  font-style: normal !important;
  margin-bottom: 10px !important;
}

.login .MuiTypography-body1 {
  font-size: 12px !important;
}

.enable {
  border-radius: 24px !important;
  background-image: linear-gradient(223.1deg, #6C328B 0%, #EF2B4B 93.2%) !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #FCFCFD !important;
  text-transform: capitalize !important;
}

.disable {
  border-radius: 24px !important;
  background: #92929D !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #FCFCFD !important;
  text-transform: capitalize !important;
}

.login .MuiInputBase-input {
  font-size: 13px !important;
}

.login .MuiInputBase-input:focus {
  font-size: 13px !important;
}

.login .MuiInputBase-input:hover {
  border-color: rgba(230, 232, 236, 1) !important;
}

.login .MuiSvgIcon-root {
  font-size: 0.9rem !important;
}

.login .typo {
  width: 100% !important;
  margin-top: 46px !important;
  margin-bottom: 10px !important;
  align-self: end;
}

.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.form-container {
  margin: auto;
}

.forgot-text {
  font-size: 12px !important;
  text-align: end !important;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: #6C328B !important;
  background: none !important;
  text-decoration: none !important;
}

.login .user-form {
  justify-content: center;
}

.login .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(230, 232, 236, 1);
}

.login .MuiOutlinedInput-notchedOutline {
  border: 2px solid rgba(230, 232, 236, 1) !important;
}

.login .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #f44336 !important;
}

.login .MuiInputBase-input::placeholder {
  font-style: normal !important;
  font-weight: 501 !important;
  font-size: 14px !important;
  color: #777e90 !important;
}

.login .forgot-content {
  text-align: end;
}

.login .MuiFormHelperText-root.Mui-error {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  color: #FF0000 !important;
}