@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.Toastify {
  height: auto !important;
}

.profileName {
    font-family: Montserrat, sans-serif !important;
    font-weight: 700 !important;
    word-break: break-all;
}

.profileName1 {
    font-family: Montserrat, sans-serif !important;
    font-weight: 400 !important;
    font-size: 14px !important;
}

.SubscriptionPayment {
    font-family: Montserrat, sans-serif !important;
    font-weight: 700 !important;
    font-size: 20px !important;
}

.line_profile {
    width: 1px;
    height: 65px;
    border: 1px solid #979797;
    color: #979797;
}

.mask {
    width: 236px;
    height: 236px;
    border-radius: 16px;
}

.billImage {
    margin-top: -13%;
    margin-left: -5%;
}

.modalStyle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile_header {
    font-weight: bold !important;
    margin-top: -15px !important;
    margin-bottom: 30px !important;
    padding-left: 25px !important;
    cursor: pointer
}

.backButtonBox {
    margin: auto;
    margin-right: initial;
    margin-bottom: 25px;
}

.scrollbar::-webkit-scrollbar {
    display: none;
}

.EditEmail .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
    padding: 16px 24px;
    border-radius: 15px;
    background: #E6E8EC;
    background: var(--border, #E6E8EC);
}

.EditEmail1 .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
    padding: 16px 24px;
    border-radius: 15px;
}

.separator {
    display: none; 
  }
  
  .otp-container {
    display: flex;
    justify-content: space-between;
    margin: auto; 
    padding: 30px 25px;
  }

  .otp-container .otp-input {
    width: 100px;
    height: 100px;
    text-align: center;
    border-radius: 15px;
    border: 1px solid #E6E8EC;
    background-color: #FFFFFF;
  }
  
  .otp-container .otp-input-disabled {
    background-color: #E6E8EC;
  }
  
  .react-otp-input {
    width: 40px; 
    height: 40px; 
    font-size: 18px; 
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

.dialogSort_Report .MuiDialog-paperWidthSm {
    max-width: 204px;
    margin-left: 78%;
    margin-top: 11%;
    box-shadow: rgb(0 0 0 / 45%) 0px 3px 10px;
    border-radius: 10px;
}

.dialog_filter .MuiDialog-paperWidthSm {
    max-width: 204px;
    box-shadow: rgb(0 0 0 / 45%) 0px 3px 10px;
    border-radius: 10px;
    margin-top: 10%;
    margin-left: 67%;
    max-height: 65%;
  }

.backButton {
    width: 135px !important;
    height: 56px !important;
    background-color: #6C328B !important;
    color: #fff !important;
    border-radius: 20px !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    text-transform: capitalize !important;
}

.shadowStyle {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.addnoteicon {
    position: absolute;
    margin-left: -30px;
    margin-top: 8px;
}

.attachicon {
    transform: rotate(320deg);
}

.linkicon {
    transform: rotate(45deg);
}

.discription {
    height: 5%;
    padding: 10px;
    width: 100%;
    border-radius: 4px;
    resize: none;
}

.MuiInput-underline {
    border: 1px solid rgba(0, 0, 0, 0.32);
    border-radius: 4px;
}

.notes-modal .MuiInput-underline::before {
    border: none !important;
}

.attchfile-icon {
    position: absolute;
    margin-left: -30px;
    margin-top: 7px;
}

.text-resize {
    resize: none;
}

.seicon {
    width: 105px;
}

.public-active-box {
    background-color: rgba(255, 139, 63, 0.5);

}

.private-active-box {
    background-color: rgba(128, 0, 128, 0.5);
}

.public-active-border {
    border-right: solid 15px rgb(255, 139, 63);


}

.private-active-border {
    border-right: solid 15px rgb(128, 0, 128);
}

/* 
.activeleft{
    background-color: rgba(255,139,63,0.5);  

} */

/* .public-left{
    border-right: purple;
} */

.imageButton {
    margin-bottom: 5px;
    background-color: rgb(156, 39, 176);
    border-radius: 10px;
    width: 70px;
    height: 35px;
    margin-left: 70px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.view_button {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.view_button p {
    display: flex;
    align-items: center;
    color: rgb(108, 50, 139);
    font-weight: bold;
    cursor: pointer;
}

.view_button svg {
    font-size: 15px;
    margin-left: 5px;
}

.barChart {
    width: 100%;
    height: 400px;
    margin-top: 20px;
}

.barChart canvas {
    width: 100% !important;
    height: 380px !important;
}

.graph_num {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    color: #9c27b0;
}

.graph1 {
    font-size: 40px !important;
    font-weight: 700;
}

.graph2 {
    margin-top: 0px !important;
    font-size: 20px !important;
    font-weight: 500;
}

.inputRounded2 .MuiOutlinedInput-root {
    border-radius: 10px;
    height: 50px;
}

.title_edit .MuiInputBase-fullWidth {
    width: 100%;
    padding: 8px;
    border-radius: 15px;
}

.react-quill .quill {
    height: 140px !important;
}

.react-quill .ql-container {
    height: 140px !important;
}

.react-quill .ql-toolbar.ql-snow {
    border-radius: 15px 15px 0 0;
}

.react-quill .ql-container.ql-snow {
    border: 1px solid #ccc;
    border-radius: 0 0 15px 15px;
}

.react-quill {
    margin: 10px 0;
    border-radius: 10px;
}

.react-quill .ql-editor {
    height: 140px !important;
}
.table-container {
  overflow-x: auto;
}

.table {
  border-collapse: separate;
  border-spacing: 0 15px;
  width: 100%;
}

.th {
  box-shadow: none;
  background: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #101010;
}

.td {
  text-align: center;
  background: #ffffff;
  padding: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #101010;
  height: 68px;
}

.td:first-child {
  border-bottom-left-radius: 18px;
  border-top-left-radius: 18px;
}

.td:last-child {
  border-bottom-right-radius: 18px;
  border-top-right-radius: 18px;
}

.tr {
  box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
}

thead .tr {
  box-shadow: none;
}

.selectall-btn .MuiButton-label {
  color: #1a1a1a;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  text-transform: none;
}

.pagination .arrow-icon {
  font-size: 30px !important;
}

.pagination .btn {
  color: #6c328b;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: none;
}

#course-program-table .MuiTableCell-head {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #1E253C !important;
}

#course-program-table .MuiTableHead-root th {
    border-bottom: none !important;
}

#course-program-table .MuiTableRow-root {
    box-shadow: 0px 0px 10px rgba(229, 229, 229, 0.478939);
}

#course-program-table .MuiTableCell-paddingCheckbox {
    padding: 16px !important;
}

#course-program-table .MuiTableCell-alignRight {
    text-align: center !important;
    padding: 15px 20px !important;
    flex-direction: column-reverse !important;
}

#course-program-table .MuiChip-outlined {
    background: #e1e3fb !important;
    mix-blend-mode: normal !important;
    border-radius: 4px !important;
    border: none !important;
}

#course-program-table .MuiChip-label {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 12px;
    text-align: center;
    color: #525CE5;
}

.last_pagination {
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
}

.Table_textAll {
    text-decoration: underline;
    font-size: 16px !important;
    font-weight: 400 !important;
    margin: auto !important;
    margin-left: 30px !important;
    cursor:  pointer !important;
}
.table .MuiSelect-icon{
    top: 3px !important;
}

.list .select-course .MuiInputBase-root .MuiSelect-icon{
    display: flex;
    margin-top: 12px;
}

.vertical-line {
    border-right: 1px solid #000; 
    height: 24px; 
    margin-right: 10px; 
  }

.list .search-outline .search .MuiOutlinedInput-root {
    max-width: 440px !important;
}

.list .MuiInputLabel-root {
    margin-bottom: 8px;
    margin-top: 5px;
}

.list .MuiInputBase-input {
    font-size: 13px !important;
    border: 1px solid #fff !important;
    height: 1em !important;

}

.list .MuiInputBase-input:focus {
    font-size: 13px !important;
} 

.list .MuiInputBase-input:hover {
    border-color: #fff !important;
}

.list .MuiTableSortLabel-iconDirectionAsc {
    transform: rotate(180deg);
    display: none;  
}

.list .MuiTableSortLabel-iconDirectionDesc {
    transform: rotate(0deg);
    display: none;
}

.list .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #DEDEDE !important;
}

.list .MuiOutlinedInput-notchedOutline {
    border: 2px solid #DEDEDE !important;
}

.list .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #f44336 !important;
}

.list .MuiInputAdornment-positionStart .MuiSvgIcon-root {
    fill: #DEDEDE !important;
    font-size: 2.0rem !important;
}

.list .course .MuiOutlinedInput-root {
    border-radius: 0 !important;
}

.list .filter-label .MuiTypography-colorTextSecondary {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 13px !important;
    line-height: 18px !important;
    letter-spacing: 0.325px !important;
    color: #58606A !important;
}

.list .course-btn {
    background: #6C328B !important;
    border-radius: 16px !important;
    height: 56px !important;
    width: 196px;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 16px !important;
    text-align: center !important;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
    margin-left: 30px !important;
}

.list .search-outline .MuiOutlinedInput-notchedOutline {
    border-radius: 18px;
    border: 2px solid #DEDEDE !important;
}

.list .box {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}

.list .box .select-course .MuiInputBase-input {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 13px !important;
    line-height: 18px !important;
    letter-spacing: 0.325px !important;
    color: #122239 !important;
}

.list .MuiMenu-paper {
    margin-top: 210px !important
}

.dailog_btn {
    margin: 32px;
    width: 450px;
    position: relative;
    /* overflow-y: auto; */
    height: 85px;
    padding: 14px;
}
.course .MuiInputBase-root .MuiSelect-icon {
  top: 10px !important;
}

.course .MuiOutlinedInput-root {
  border-radius: 12px !important;
}

.course .MuiInputLabel-root {
  margin-bottom: 8px;
  margin-top: 5px;
}

.course .MuiInputBase-input {
  height: 1em !important;
  font-size: 16px !important;
  font-weight: 500;
  color:black;
}

.course .MuiInputBase-input:focus {
  font-size: 16px !important;
}

.course .MuiInputBase-input:hover {
  border-color: rgba(230, 232, 236, 1) !important;
}

.course .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(230, 232, 236, 1) !important;
}

.course .MuiOutlinedInput-notchedOutline {
  border: 2px solid rgba(230, 232, 236, 1) !important;
}

.course .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #f44336 !important;
}

.course .MuiInput-underline {
  border: none !important;
}

.course .MuiInputBase-input::-moz-placeholder {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 26px !important;
  color: #1a1a1a !important;
}

.course .MuiInputBase-input::placeholder {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 26px !important;
  color: #1a1a1a !important;
}

.course .duplicate-btn {
  background: #6c328b !important;
  border-radius: 18px;
  width: 152px;
  height: 56px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #ffffff;
  text-transform: capitalize;
}

.changethumb-btn {
  border: 1px solid #979797 !important;
  border-radius: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 22px !important;
  color: #000000 !important;
  width: 192px !important;
  height: 56px !important;
  text-transform: capitalize !important;
}

.thumb-content .MuiGrid-grid-md-2 {
  max-width: 220px !important;
}

.contentRole-btn {
  background: #6c328b !important;
  border-radius: 16px !important;
  width: 220px !important;
  height: 55.9px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
}

.contentRole-btn .plus-icon {
  border-radius: 6px;
  width: 24px;
  font-size: 1.5rem;
}

.contentRoleContent-btn {
  border: 1px solid #6c328b !important;
  border-radius: 16px !important;
  width: 154px !important;
  height: 55.9px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #6c328b !important;
  text-transform: capitalize !important;
}

.contentRole .MuiGrid-grid-md-2 {
  max-width: unset !important;
  flex-basis: 0 !important;
}

.contentRole .custom-md-2 {
  max-width: 180px !important;
}

.contentRole-badge .MuiBadge-badge {
  border: 1px solid black !important;
  background: #fff !important;
  height: 24px !important;
  width: 24px !important;
  border-radius: 13px !important;
}

.contentRole-badge .MuiBadge-anchorOriginTopRightRectangle {
  transform: scale(1) translate(18%, -36%) !important;
}

.calender-btn {
  margin-right: 10px !important;
  background: #ef2b4b !important;
  mix-blend-mode: normal !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
}

.calender-btnoutlined {
  margin-right: 10px !important;
  background: #ffffff !important;
  border: 1px solid #ef2b4b !important;
  border-radius: 6px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  color: #ef2b4b !important;
  text-transform: capitalize !important;
}

.react-calendar .react-calendar__month-view__days button {
  margin: 2px !important;
  max-width: 36.9px !important;
}

.add-reward .MuiOutlinedInput-notchedOutline {
  mix-blend-mode: normal !important;
  opacity: 0.84 !important;
  border: 1px solid #545650 !important;
  border-radius: 8px !important;
}

.add-reward .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  mix-blend-mode: normal !important;
  opacity: 0.84 !important;
  border: 1px solid #545650 !important;
  border-radius: 8px !important;
}

.add-reward .input-label {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 50px;
  color: #000000;
}

.add-reward .user-heading {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 33px !important;
  color: #12142b !important;
}

.add-reward .outer-box {
  background: #f2f2f2;
  border-radius: 12px;
  padding: 15px;
}

.add-reward .outer-border {
  background: #fbfbfb;
  border: 0.5px solid rgba(0, 0, 0, 0.504835);
  border-radius: 8px;
  width: 100%;
  height: 376px;
  overflow: auto;
}

.add-reward .outer-border .inner-container {
  padding: 30px 30px 0px 30px;
}

.add-reward .inner-border {
  background: #ffffff;
  border: 1px dashed #cfcfcf;
  border-radius: 10px;
  justify-content: space-between;
  display: flex;
  padding: 0px 15px 0px 15px;
  flex-direction: row;
}

.add-reward .add-more-btn {
  background: #ffffff !important;
  border: 1px solid #6c328b !important;
  border-radius: 8px !important;
  padding: 10px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  text-align: center !important;
  color: #6c328b !important;
  text-transform: capitalize !important;
  margin-bottom: 30px;
  width: 100%;
}

.add-reward .inner-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6c328b;
  mix-blend-mode: normal;
  opacity: 0.97;
}

.add-reward .inner-container2 {
  padding: 30px 30px 30px 30px;
}

.add-reward .inner-border2 {
  border: 2px solid rgba(116, 118, 113, 0.242488);
  border-radius: 16px;
  text-align: center;
  min-height: 162px;
  padding: 35px;
}

.add-reward .dashed-border {
  width: 100%;
  display: flex;
  background: #ffffff;
  border: 2.5px dashed #979797;
  border-radius: 8px;
  align-items: center;
  min-height: 370px;
}

.add-reward .text {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #545650;
  mix-blend-mode: normal;
  opacity: 0.81;
}

.add-reward .or-text {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #545650;
}

.add-reward .inner-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}

.add-reward .create-reward-btn {
  background: #6c328b !important;
  border-radius: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #ffffff !important;
  height: 56px !important;
  width: 302px !important;
}

.add-reward .dashed-border-achievement {
  width: 100%;
  display: flex;
  background: #ffffff;
  border: 2.5px dashed #979797;
  border-radius: 8px;
  align-items: center;
  padding: 35px;
}

#files {
  opacity: 0;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;

  /* older Safari browsers */
  -khtml-opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.add-reward .img-preview {
  border-radius: 12px;
  width: 100%;
  height: 223px;
}

.add_preview_img {
  padding: 0 20px;
}

.add_preview_img .file-name-container {
  display: flex;
  align-items: center;
}

.add_preview_img .filename-btn {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start;
  padding: 15px !important;
}

.add_preview_img .filename-btn img {
  margin-right: 15px;
}

.add_preview_img .trash-btn {
  padding: 0;
  margin-left: 15px;
}

.add_preview_img .trash-btn img {
  width: 100%;
}

.build-course .build-course-img {
  height: 80px;
  width: 80px;
  border: 1px solid rgba(225, 225, 225, 0.577997);
  filter: drop-shadow(0px 0px 16px rgba(213, 213, 213, 0.350581));
  border-radius: 8px;
}

.build-course .build-content-border {
  background:  #ECECEC;
  border-radius: 12px;
  padding: 24px;
  width: 100%;
  margin-top: 10px;
}

.build-course .build-course-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #1a1a1a;
}

.build-course .build-course-subheading {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.4px;
  color: #1a1a1a;
}

.build-course .build-container {
  text-align: center;
}

.build-course .MuiCardContent-root:last-child {
  padding-bottom: 0px !important;
}

.build-course .build-course-title {
  padding: 3px 10px 13px 10px;
  text-align: start;
}

.build-course .text-center {
  text-align: center;
}

.build-course .build-course-subheading-desc {
  height: 20px;
  overflow: clip;
}

.build-course .customize-border {
  /* background: #FFFFFF !important; */
  border: 1px solid rgba(225, 225, 225, 0.577997) !important;
  border-radius: 12px !important;
  margin-top: 20px !important;
}

.build-course .customize-dashed-border {
  background: #ffffff !important;
  border: 1px dashed #cfcfcf !important;
  border-radius: 10px !important;
  margin-top: 20px !important;
  overflow: auto;
  max-height: -webkit-fill-available;
  box-shadow: none;
}

.build-course .reward-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.3px;
  color: #3f526d;
  overflow: clip;
  
}

.build-course .achieve-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.3px;
  color: #3f526d;
  overflow: clip;
}

.build-course .reward-icon {
  width: 80px;
  height: 80px;
  background: rgb(244, 32, 116);
  box-shadow: rgba(244, 32, 116, 0.65) 0px 11px 11px -6px;
  border-radius: 10px;
  padding: 10px;
  margin: auto;
}

.build-course .achieve-icon {
  background: #8636a3;
  box-shadow: 0px 11px 11px -6px #6C328BC7;
  height: 80px;
  width: 80px;
  border-radius: 10px;
  padding: 10px;
  margin: auto;
}

.build-course .Addmore-icon {
  background: #8636a3;
  box-shadow: 0px 11px 11px -6px #6C328BC7;
  height: 80px;
  width: 80px;
  border-radius: 10px;
  padding: 22px;
}


.build-course .reward-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #3f526d;
}

.build-course .aft-text {
  margin: 50px 0 10px 5px;
  font-size: 22px;
  font-weight: 530;
}

.build-course .achieve-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #3f526d;
}

.add-reward .filename-btn {
  background: #f7f3f8;
  mix-blend-mode: normal;
  border-radius: 8px;
  height: 64px;
  width: 280px;
  padding: 0;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0 5px 0 5px;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.add-reward .change-thumbnail-btn1 {
  background: #ffffff;
  border: 1px solid #6c328b;
  border-radius: 8px;
  text-transform: capitalize;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 35px;
  color: #6c328b;
  width: 100%;
  margin-top: 35px;
}

.course-section.head-text-launch-date {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #1a1a1a;
}

.course .sub-text-launch-date {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  color: #9f9f9f;
}

.CourseCreation-listItemTimeline-61 {
  width: 100%;
}

.course-section .MuiFormControl-root.select-notify {
  width: 100%;
  background: #ffffff;
  mix-blend-mode: normal;
  opacity: 0.84;
  border: 1px solid #545650;
  border-radius: 8px;
}

.course-section .search .MuiOutlinedInput-notchedOutline {
  border-radius: 16px !important;
  border: 2px solid #dedede !important;
  max-width: 240px;
  width: 100%;
  max-height: 55px;
}

.course-section .MuiFormControl-root.select-phases {
  width: 100%;
  background: #ffffff;

  border-radius: 8px;
}

.course-section .MuiFormControl-root.object-type {
  width: 100%;
  background: #ffffff;
  mix-blend-mode: normal;
  opacity: 0.84;
  /* border: 1px solid #545650; */
  border-radius: 8px;
}

.course-section .dashed-border {
  background: #ffffff;
  border: 0.5px dashed #979797;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 25px 20px 20px 20px;
}

.course-section .MuiInput-underline::after {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.course-section .MuiInput-underline {
  border: none;
  border-radius: 4px;
}

.course-section .notify-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.course-section .calender {
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin: 10px;
}

.category-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #1a1a1a;
}

.btn-background {
  background: #6c328b !important;
    color: #fff !important;
}

.course-desc {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  color: #000000 !important;
}

.course-logic-options {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #1a1a1a !important;
}

.toggle-show-label {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #000000 !important;
}

.toggle-hide-label {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 26px !important;
  color: #1a1a1a !important;
}

.draft-btn {
  background: #fafafa;
  border-radius: 18px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  text-align: center !important;
  letter-spacing: 0.4px !important;
  color: #ff8b3f !important;
  max-width: 532px !important;
  height: 56px !important;
  text-transform: capitalize !important;
  margin-top: 15px !important;
}

.proceed-content-btn {
  background: #ff8b3f !important;
  max-width: 532px !important;
  height: 56px !important;
  border-radius: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  text-align: center !important;
  letter-spacing: 0.3px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  margin-top: 15px !important;
}

.main-content-dialog-grid {
  text-align: center;
  align-items: center !important;
  flex-wrap: nowrap !important;
}

.plain-copy {
  width: 48px;
  height: 48px;
}

.dialog-text {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #fcfcfd;
  margin: 15px 10px 0 0 !important;
}

.quiz-icon {
  width: 48px;
  height: 48px;
  margin: 0;
}

.custom-line::after {
  content: " ";
  display: block;
  position: absolute;
  height: 1px;
  background: #b86ae2;
  width: 177px;
  left: 28%;
  top: calc(50% - 5px);
  rotate: 90deg;
}

.select-img-preview {
  width: 150px !important;
  height: 150px !important;
  border: 0.5px solid rgba(0, 0, 0, 0.101644) !important;
  box-sizing: border-box !important;
}

.custom-card-preview .MuiPaper-elevation1 {
  box-shadow: none !important;
}

.card-header {
  height: 30px !important;
  text-align: end !important;
}

.file-name {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #000000;
  text-align: center;
  margin: 0;
  text-overflow: clip;
  width: 197px;
  overflow: hidden;
  white-space: nowrap;
}

.icon-library .MuiCard-root {
  background: #ffffff !important;
  box-shadow: -6px -6px 25px rgba(230, 230, 230, 0.25),
    6px 6px 25px rgba(230, 230, 230, 0.25), 6px 6px 25px rgba(0, 0, 0, 0.12) !important;
  border-radius: 20px !important;
}

.icon-library .MuiCardHeader-content {
  padding: 10px !important;
}

.icon-library .MuiCardHeader-content .MuiTypography-h5 {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #1a1a1a !important;
}

.icon-library .paper-content {
  text-align: center !important;
  height: 80px;
}

.icon-library .paper-content .icon-svg {
  height: 50px;
  width: 50px;
  margin: 20px;
}

.icon-library .paper-container .paper-text {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  background: #6c328b !important;
  border-radius: 0px 0px 8px 8px !important;
  padding: 10px !important;
  color: #fff !important;
  margin: 0;
  text-align: center !important;
}

.icon-library .paper-container .paper-text .copies-icon {
  width: 15px;
  height: 15px;
  position: absolute;
}

.icon-library .paper-container {
  background: #ffffff !important;
  border-radius: 8px !important;
}

.icon-library .btn-add-icon {
  background: #653889 !important;
  border-radius: 18px !important;
  width: 200px !important;
  height: 56px !important;
  color: #fff !important;
  text-transform: capitalize !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
}

.icon-library .toggle-text {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 35px !important;
  color: #6c328b !important;
}

.icon-library .MuiSwitch-root {
  margin: 15px !important;
}

.icon-library .folder {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  text-align: center !important;
  color: #979797 !important;
  text-decoration: none !important;
}

.icon-library .MuiOutlinedInput-notchedOutline {
  border: 1px solid #d6d6d6 !important;
  border-radius: 18px !important;
}

.icon-library .toggle-content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.icon-library .branch-select {
  width: 100% !important;
}

.icon-library .select-filter {
  width: 100% !important;
}

.icon-library .select-filter .MuiSelect-selectMenu {
  border-right: 1px solid #e7e7e7 !important;
}

.icon-library .tableContainer {
  border-collapse: separate;
  border-spacing: 0 15px;
  width: 100%;
}

.icon-library .tableContainer th {
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #101010;
}

.icon-library th {
  width: 150px;
  text-align: center;
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
  padding: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #101010;
  background: #ffffff;
}

.icon-library td {
  width: 150px;
  text-align: center;
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
  padding: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #101010;
  background: #ffffff;
  height: 70px;
}

.icon-library .tableColumn-container {
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
}

.icon-library .tableColumn-container:last-child td:last-child {
  border-bottom-right-radius: 20px;
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
}

.icon-library .tableColumn-container:last-child td:first-child {
  border-bottom-left-radius: 20px;
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
}

.add-folder .select-folder-btn {
  background: #ffffff !important;
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
  box-shadow: -6px -6px 25px rgba(230, 230, 230, 0.25),
    6px 6px 25px rgba(229, 229, 229, 0.25) !important;
  border-radius: 10px !important;
  box-sizing: border-box !important;
  width: 220px !important;
  height: 70px !important;
  text-transform: capitalize !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #222836 !important;
  justify-content: space-evenly !important;
}

.add-folder .add-folder-btn {
  background: #653889 !important;
  border-radius: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  height: 60px !important;
  width: 175px !important;
}

.add-folder .folder-text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: start;
  color: #979797;
  text-transform: capitalize;
}

.icon-library .branch-select .label-select {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #979797;
}

.reward-list .mainContainer {
  background: #fafafa;
  border: 1px solid #e7e7e7;
  border-radius: 20px;
  padding: 30px;
  overflow-x: auto;
}

.reward-list .tableContainer {
  border-collapse: separate;
  border-spacing: 0 15px;
  width: 100%;
}

.reward-list .tableContainer th {
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #101010;
}

.reward-list th {
  text-align: center;
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
  padding: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #101010;
}

.reward-list td {
  text-align: center;
  background: #ffffff;
  border-width: 1px 0px;
  border-style: solid;
  border-color: #979797;
  box-shadow: -6px -6px 25px rgba(230, 230, 230, 0.15),
    6px 6px 25px rgba(229, 229, 229, 0.15);
  padding: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #101010;
  height: 50px;
}

.reward-list td:last-child {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #979797;
  border-left-color: #97979700;
}

.reward-list td:first-child {
  border: 0.5px solid #979797;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-right-color: #97979700;
}

.reward-list .MuiOutlinedInput-notchedOutline {
  border: 1px solid #d6d6d6 !important;
  border-radius: 18px !important;
}

.reward-list .bulk-action {
  width: 100% !important;
}

.reward-list .create-reward-btn {
  background: #653889 !important;
  border-radius: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  height: 60px !important;
  width: 175px !important;
}

.reward-list .label-reward {
  margin: 8px;
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #1a1a1a;
}

.reward-list .content-role-heading {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #000000 !important;
}

.reward-list .filter-heading {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  color: #545650 !important;
}

.reward-list .input-label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
}

.achievement-list .create-reward-btn {
  background: #653889 !important;
  border-radius: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  height: 60px !important;
  width: 230px !important;
}

.achievement-list .label-reward {
  margin: 8px;
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  color: #1a1a1a;
}

.achievement-list .MuiOutlinedInput-notchedOutline {
  border: 1px solid #d6d6d6 !important;
  border-radius: 18px !important;
}

.achievement-list .bulk-action {
  width: 100% !important;
}

.achievement-list .mainContainer {
  background: #fafafa;
  border: 1px solid #e7e7e7;
  border-radius: 20px;
  padding: 30px;
  overflow-x: auto;
}

.achievement-list .tableContainer {
  border-collapse: separate;
  border-spacing: 0 15px;
  width: 100%;
}

.achievement-list .tableContainer th {
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #101010;
}

.achievement-list th {
  text-align: center;
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
  padding: 5px;
  color: #101010;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.achievement-list td {
  text-align: center;
  background: #ffffff;
  border-width: 1px 0px;
  border-style: solid;
  border-color: #979797;
  box-shadow: -6px -6px 25px rgba(230, 230, 230, 0.15),
    6px 6px 25px rgba(229, 229, 229, 0.15);
  padding: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #101010;
  height: 50px;
}

.achievement-list td:last-child {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #979797;
  border-left-color: #97979700;
}

.achievement-list td:first-child {
  border: 0.5px solid #979797;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-right-color: #97979700;
}

.build-course .user-box {
  box-shadow: 0px 5px 5px -3px #0000001A;
  border-radius: 8px !important;
  background: #ffffff !important;
  margin-bottom: 15px;
}

.course-section .react-calendar {
  width: 100% !important;
}

.course-section .course .edit-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #6c328b;
  text-decoration: underline;
  margin: 0;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion-summary-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MuiAccordion-root:before {
  height: 0 !important;
}

@media screen and (max-width: 500px) {
  .thumbpreview {
    text-align: end !important;
  }

  .contentRole {
    display: block !important;
    text-align: center !important;
  }

  .contentRoleContent-btn {
    border: 1px solid #6c328b !important;
    border-radius: 16px !important;
    width: 90vw !important;
    height: 55.9px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    line-height: 16px !important;
    color: #6c328b !important;
    text-transform: capitalize !important;
    justify-content: center;
  }

  .MuiFormControl-fullWidth {
    min-width: 89vw;
  }
}

@media screen and (max-width: 900px) {
  .thumbpreview {
    text-align: end !important;
  }

  .contentRole {
    display: block !important;
    text-align: center !important;
  }

  .contentRoleContent-btn {
    border: 1px solid #6c328b !important;
    border-radius: 16px !important;
    width: 80vw !important;
    height: 55.9px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    line-height: 16px !important;
    color: #6c328b !important;
    text-transform: capitalize !important;
    justify-content: center;
  }

  .MuiFormControl-fullWidth {
    min-width: 89vw;
  }
}

@media screen and (max-width: 1200px) {
  .thumbpreview {
    text-align: end !important;
  }

  .contentRole {
    display: block !important;
    text-align: center !important;
  }

  .contentRoleContent-btn {
    border: 1px solid #6c328b !important;
    border-radius: 16px !important;
    width: 85vw !important;
    height: 55.9px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    line-height: 16px !important;
    color: #6c328b !important;
    text-transform: capitalize !important;
    justify-content: center;
  }

  .MuiFormControl-fullWidth {
    min-width: 89vw;
  }
}

.bottom-title {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #545650 !important;
}

a {
  text-decoration: none !important;
}

.thumbpreview {
  max-width: unset !important;
  flex-basis: unset !important;
}

.custom-dialog2 .MuiDialog-paperScrollPaper {
  max-height: 170px !important;
  height: 100% !important;
  border-radius: 16px !important;
}

#dialog-title .MuiDialogContent-root {
  display: flex !important;
}

.build-course .MuiDialog-container .MuiPaper-rounded {
  border-radius: 16px !important;
}

.course-section .react-calendar .react-calendar__month-view__days button {
  margin: 2px !important;
  max-width: 36.9px !important;
}

.build-course .build-content-border .user-box .blur-background {
  opacity: 0.4 !important;
}

.build-course .build-content-border .user-box .active-text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #6c328b;
}

.build-course .build-content-border .user-box .inactive-text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}

.no-btn {
  box-sizing: border-box !important;
  display: flex !important;
  flex-direction: row !important;
  padding: 19px 25px !important;
  grid-gap: 10px !important;
  gap: 10px !important;
  width: 160px !important;
  height: 56px !important;
  border: 1px solid #6c328b !important;
  border-radius: 16px !important;
  color: #6c328b !important;
}

.yes-btn {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 19px 40px !important;
  grid-gap: 10px !important;
  gap: 10px !important;
  width: 160px !important;
  height: 56px !important;
  background: #6c328b !important;
  border-radius: 16px !important;
  color: white !important;
}

.custom-dialog3 .MuiDialog-paperScrollPaper {
  background: #fafafa !important;
  box-shadow: 0px 12px 17px 2px rgba(0, 0, 0, 0.14),
    0px 5px 22px 4px rgba(0, 0, 0, 0.12), 0px 7px 8px -4px rgba(0, 0, 0, 0.2) !important;
  border-radius: 12px !important;
}

#alert-dialog-title-delete .MuiTypography-h6 {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 32px !important;
  color: #000000 !important;
}

#alert-dialog-description-delete {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  letter-spacing: 0.3px !important;
  color: #000000 !important;
}

.custom-line1::after {
  content: " ";
  position: absolute;
  background: #b86ae2;
  top: -41px;
  right: 0;
  width: 1px;
  height: 167px;
}

.dialog-text1 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #fcfcfd;
  margin: 15px 3px 0 0 !important;
}

.existing_course .MuiOutlinedInput-notchedOutline {
  border: 1px solid #D6D6D6 !important;
  border-radius: 18px !important;
}

.existing_course .filter-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #545650;
}

.existing_course .confirm-btn{
    padding: 19px 40px !important;
    grid-gap: 10px !important;
    gap: 10px !important;
    width: 209px !important;
    height: 56px !important;
    background: #6c328b !important;
    border-radius: 16px !important;
    color: white !important;
    text-transform: capitalize;
}
.cartForm input{
    padding: 6px 10px 7px !important;
}

.cartForm .MuiInput-underline::before{
    display: none;
}

.cartForm .MuiInput-underline::after{
    display: none;
}

.cartForm .MuiInput-underline{
    border: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.32);
}
.cartForm input {
  padding: 6px 10px 7px !important;
}

.cartForm .MuiInput-underline::before {
  display: none;
}

.cartForm .MuiInput-underline::after {
  display: none;
}

.cartForm .MuiInput-underline {
  border: 0px;
  border-bottom: 1px solid #979797;
}

/* Swipeable cards */
.saved-cards .slick-list {
  padding: 0 !important;
}

.saved-cards .slick-slide {
  width: 230px !important;
}

.saved-cards .slick-track {
  margin-left: 0;
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}
.forgotPassword .login-route {
  width: 100%;
}

.forgotPassword .mt-10 {
  margin-top: 12%;
}

.forgotPassword .login-route .MuiPaper-elevation1 {
  float: right;
  box-shadow: none;
  font-family: 'Montserrat', sans-serif;
}

.forgotPassword .login-route .text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #23262F;
  cursor: pointer !important;
  background: none !important;
  text-decoration: none !important;
}

.forgotPassword .form {
  margin-top: 20px;
  justify-content: center;
}

.forgotPassword .form .uiTypography-root .MuiTypography-h3 {
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  text-align: start;
}

.forgotPassword .regform {
  justify-content: center;
}

.forgotPassword .MuiTypography-h5 {
  font-size: 32px !important;
  font-weight: 700 !important;
  color: #23262F !important;
  font-style: normal !important;
}

.forgotPassword .MuiTypography-h6 {
  font-size: 14px !important;
  font-weight: 400;
  color: #777E90 !important;
  font-style: normal !important;
}

.forgotPassword .MuiFormLabel-root {
  font-size: 12px !important;
  font-weight: 501 !important;
  color: #777E90 !important;
  font-style: normal !important;
}

.forgotPassword .MuiInputBase-input {
  height: 0.5em !important;
  font-size: 13px !important;
}

.forgotPassword .MuiOutlinedInput-root {
  border-radius: 12px !important;
}

.forgotPassword .MuiInputLabel-root {
  margin-bottom: 8px;
  margin-top: 5px;
}

.forgotPassword .MuiTypography-body1 {
  font-size: 12px !important;
}

.enable {
  border-radius: 24px !important;
  background-image: linear-gradient(223.1deg, #6C328B 0%, #EF2B4B 93.2%) !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #FCFCFD !important;
  text-transform: capitalize !important;
}

.disable {
  border-radius: 24px !important;
  background: #92929D !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #FCFCFD !important;
  text-transform: capitalize !important;
}

.forgotPassword .MuiInputBase-input:focus {
  font-size: 13px !important;
}

.forgotPassword .MuiSvgIcon-root {
  font-size: 0.9rem !important;
}

.forgotPassword .typo {
  width: 100% !important;
  margin-top: 46px !important;
  margin-bottom: 26px !important;
  align-self: end;
}


.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.form-container {
  margin: auto;
}

.forgot-text {
  font-size: 15px !important;
  font-family: 'Montserrat', sans-serif !important;
  text-align: end !important;
  color: #3772FE !important;
  font-weight: 600;
  background: none !important;
}

.forgotPassword .forgot-heading {
  font-weight: 700 !important;
}

.forgotPassword .label-input {
  text-align: start;
}

.forgotPassword .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(230, 232, 236, 1);
}

.forgotPassword .MuiOutlinedInput-notchedOutline {
  border: 2px solid rgba(230, 232, 236, 1) !important;
}

.forgotPassword .MuiInputBase-input:hover {
  border-color: rgba(230, 232, 236, 1) !important;
}

.forgotPassword .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #f44336 !important;
}

.forgotPassword .MuiInputBase-input::-moz-placeholder {
  font-style: normal !important;
  font-weight: 501 !important;
  font-size: 14px !important;
  color: #777e90 !important;
}

.forgotPassword .MuiInputBase-input::placeholder {
  font-style: normal !important;
  font-weight: 501 !important;
  font-size: 14px !important;
  color: #777e90 !important;
}

.forgotPassword .MuiFormHelperText-root.Mui-error {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  color: #FF0000 !important;
  display: flex !important;
  align-items: center !important;
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.dialog_libraryPersonle {
    max-width: 204px;
    box-shadow: rgb(0 0 0 / 45%) 0px 3px 10px;
    border-radius: 10px;
    margin-top: 9%;
    margin-left: 65%;
    max-height: 65%;
}

.rangeInput {
  width: 100%;
}

.rangeInput .MuiSlider-track {
  height: 10px;
  border-radius: 15px;
  background: linear-gradient(223.1deg, #6C328B 0%, #EF2B4B 93.2%);
}

.rangeInput .MuiSlider-rail {
  height: 10px;
  border-radius: 15px;
  background: #9E9E9E;
}

.rangeInput .MuiSlider-thumb::after{
  position: unset;
}

.rangeInput .MuiSlider-thumb {
  background: linear-gradient(223.1deg, #6C328B 0%, #EF2B4B 93.2%);
  margin-top: -3px;
  width: 16px;
  height: 16px;
}

.numberStyles {
  padding: 10px 15px;
  border-radius: 12px;
  margin-right: 15px;
  border: 1px solid rgba(225, 225, 225, 0.577997);
  color: #6C328B;
  font-weight: 700;
  box-shadow: 0px 0px 16px rgba(213, 213, 213, 0.350581);
}

.videoPlayActiv {
  cursor: pointer;
  border: 1px solid #6c328b;
  box-shadow: 0px 0px 11px #6c328b, 0px 0px 0px #6c328b;
}

.videoPlayList:hover {
  cursor: pointer;
  border: 1px solid #6c328b;
  box-shadow: 0px 0px 11px #6c328b, 0px 0px 0px #6c328b;
}

.cardStyle {
  border-radius: 13px;
  background: #e0e0e0;
  box-shadow: 7px 7px 13px #bebebe, -7px -7px 13px #ffffff;
}

.cardStyle1 {
  border-radius: 16px;
  background: #e0e0e0;
  box-shadow: 11px 11px 22px #bababa,
    -11px -11px 22px #ffffff;
}


.imageView {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.image2View {
  border: 1px solid green;
  margin-left: 10px;
  position: absolute;
  margin-top: -75px;
}

.next {
  background-color: #1c1a1a;
  width: 10px;
  color: white;
}

.round {
  border-radius: 50%;
}

.latest slick-slide {
  width: auto !important;
  margin: -1px;
}

.my-carousel {
  width: 100%;
}

.slider1 .slick-track {
  margin-left: 0px;
}

.slider1 .slick-prev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 50px;
  border-radius: 30px;
  height: 50px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: #11142d;
  border: none;
  outline: none;
  background: #11142d;
  z-index: 500;
}

.slider1 .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 50px;
  border-radius: 30px;
  height: 50px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: #11142d;
  border: none;
  outline: none;
  background: #11142d;
}

.slider1 .slick-next:hover {
  background: #11142d;
}

.slider1 .slick-prev:hover {
  background: #11142d;
}

.container {
  position: relative;
  width: 100%;
  max-width: 300px;
  z-index: -1;
}

.image {
  display: block !important;
  width: 100% !important;
  height: 243px !important;
  border-radius: 25px 25px 0 0 !important;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  padding: 76px;
  text-align: center;
  border-radius: 25px 25px 0 0 !important;
}

.container .overlay {
  opacity: 1;
}

.container1 {
  position: relative;
  width: 100%;
  max-width: 300px;
  z-index: -1;
  padding: 0 15px 0 15px;
}

.image1 {
  display: block !important;
  width: 100% !important;
  height: 243px !important;
  border-radius: 25px 25px 0 0 !important;
}

.overlay1 {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  min-Width: 90%;
  transition: .5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  padding: 0 76px 76px 76px;
  border-radius: 25px 25px 25px 25px !important;
  padding-top: 166px;
}

.container1 .overlay1 {
  opacity: 1;
}

.BarChart {
  height: 100%;
  width: 100%;
}

.inputRounded .MuiOutlinedInput-input {
  padding: 18.5px 32px;
}

.Rounded .MuiOutlinedInput-input {
  padding: 18.5px 45px;
}

.Rounded .MuiOutlinedInput-root {
  border-radius: 10px;
  height: 45px;
}

.inputRounded .MuiOutlinedInput-root {
  border-radius: 10px;
}

.dialog .MuiDialog-paperWidthSm {
  max-width: 204px;
  margin-left: 65%;
  margin-top: 15%;
  max-height: 65%;
  box-shadow: rgb(0 0 0 / 45%) 0px 3px 10px;
  border-radius: 10px;
}


.dialog_library .MuiDialog-paperWidthSm {
  max-width: 204px;
  box-shadow: rgb(0 0 0 / 45%) 0px 3px 10px;
  border-radius: 10px;
  margin-top: 16%;
  margin-left: -10%;
  max-height: 65%;
}

.dialogSort .MuiDialog-paperWidthSm {
  max-width: 204px;
  margin-left: 80%;
  margin-top: 14%;
  box-shadow: rgb(0 0 0 / 45%) 0px 3px 10px;
  border-radius: 10px;
}

.dialogSort_library .MuiDialog-paperWidthSm {
  max-width: 204px;
  margin-left: 10%;
  margin-top: 16%;
  box-shadow: rgb(0 0 0 / 45%) 0px 3px 10px;
  border-radius: 10px;
}

.react-quill .quill {
  height: 140px !important;
}

.react-quill .ql-formats {
  float: right;
  display: flex;
  flex-direction: row-reverse;
}

.react-quill .ql-container {
  height: 140px !important;
}

.react-quill .ql-toolbar.ql-snow {
  border-radius: 15px 15px 0 0;
}

.react-quill .ql-container.ql-snow {
  border: 1px solid #ccc;
  border-radius: 0 0 15px 15px;
}

.react-quill {
  margin: 10px 0;
  border-radius: 10px;
}

.react-quill .ql-editor {
  height: 140px !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .paper {
    width: 85vw;
  }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .paper {
    width: 85vw;
  }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .paper {
    width: 85vw;
  }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .paper {
    width: 85vw;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .paper {
    width: 90vw;
  }
}


.TextInput input {
  height: 145px;
}
.register .login-route{
     width: 100%;
 }

 .register .login-route .MuiPaper-elevation1{
  float: right;
  box-shadow: none;
  font-family: 'Montserrat', sans-serif;
}
.register .login-route  .text{font-family: 'Montserrat';font-style: normal;font-weight: 700;font-size: 14px;color: #23262F;cursor: pointer !important;background: none !important;text-decoration: none !important;}

.register .form {margin-top: 10px;}

.register .form .uiTypography-root .MuiTypography-h3{font-weight: bold;  font-family: 'Montserrat'; text-align: start;}

.register .regform{justify-content: center;}

.register .MuiTypography-h5 { font-size: 32px !important; font-weight: 700 !important;color: #23262F !important;font-style: normal !important;}

.register .MuiTypography-h6 { font-size: 14px !important; font-weight: 400;color: #777E90 !important; font-style: normal !important;}

.register .MuiFormLabel-root {font-size: 12px !important; font-family: 'Montserrat' !important;font-weight: 501 !important;color: #959595 !important;font-style: normal !important;}

.register .MuiInputBase-input {height: 0.5em !important;}

.register .MuiOutlinedInput-root {border-radius: 12px !important;}

.register .MuiInputLabel-root {margin-bottom: 8px;margin-top: 5px;}

.register .MuiTypography-body1 {font-size: 12px !important;}

.register .MuiButton-root {border-radius: 24px !important;background-image: linear-gradient(270deg, #6C328B 0%, #F42074 44.66%, #EF2B4B 100%)!important;font-family: 'Montserrat' !important;font-style: normal !important;font-weight: 700 !important;font-size: 16px !important;color: #FCFCFD !important;text-transform: capitalize !important;}

.register .MuiInputBase-input {font-size: 13px !important;}

.register .MuiInputBase-input:focus {font-size: 13px !important;}

.register .MuiSvgIcon-root {font-size: 0.9rem !important;}

.register .typo { width: 100% !important; margin-top: 46px !important; margin-bottom: 26px !important;align-self: end;}

.pr .MuiOutlinedInput-input { padding: 18.5px 53px 18.5px 14px !important;}

.invalid-feedback {width: 100%; margin-top: 0.25rem; font-size: 80%; color: #dc3545;}

.register #filled-adornment-password-helper-text { max-width: 200;}

.register .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {border-color: rgba(230, 232, 236, 1);}

.register .MuiOutlinedInput-notchedOutline {border:2px solid rgba(230, 232, 236, 1) !important;}

.register .MuiInputBase-input:hover {border-color:rgba(230, 232, 236, 1) !important;}

.register .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline { border-color: #f44336 !important;}

.register .MuiFormControlLabel-root .MuiFormControlLabel-label {font-family: 'Montserrat';font-style: normal;font-weight: 400 !important;font-size: 14px !important;line-height: 24px;color: #777E90;}

.register .MuiInputBase-input::-moz-placeholder {font-family: 'Montserrat' !important;font-style: normal !important;font-weight: 501 !important;font-size: 14px !important;color: #777e90!important;}

.register .MuiInputBase-input::placeholder {font-family: 'Montserrat' !important;font-style: normal !important;font-weight: 501 !important;font-size: 14px !important;color: #777e90!important;}
.register .terms {
  font-size: 14px !important;
  cursor: pointer;
  font-family: 'Montserrat' !important;
  font-weight: 500 !important;
  color: #6C328B !important;
  font-style: normal !important;
  margin-left: 24px;
  background: none !important;
  text-decoration: none !important;
}

.splash .custom-card {
    text-align: center !important;
    width: 100% !important;
}

.splash .back-btn {
    border-radius: 20px !important;
    height: 48px !important;
    background: #FF8B3F !important;
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    text-align: center;
    color: #FCFCFD !important;
    text-transform: capitalize !important;
}

.splash .MuiPaper-elevation1 {
    box-shadow: none;
}

@media only screen and (max-width:600px) {
    .splash .MuiList-root {
        width: 90% !important;
        margin: auto !important;
        position: relative;
        font-size: 11px;
        background-color: #fff !important;
        margin: auto !important;
        list-style-type: decimal !important;
        text-align: start;
        padding: 0px 40px;

    }

    .splash .MuiList-root {
        width: 90% !important;
        margin: auto !important;
        position: relative;
        background-color: #fff !important;
        font-size: 11px;
        margin: auto !important;
        list-style-type: decimal !important;
        text-align: start;
        padding: 0px 40px;
    }

    .splash .MuiGrid-grid-sm-6 {
        width: 50% !important;
    }

    .splash .heading {
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 25px !important;
        margin-right: 30%;
        line-height: 0px !important;
        letter-spacing: -1px !important;
        color: #11142D !important;
        font-family: "Montserrat" !important;
        margin-top: 15% !important;
    }
}

@media only screen and (min-width:900px) {
   
    .splash .MuiList-root {
        width: 100% !important;
        margin: auto;
        position: relative;
        background-color: #fff;
        font-size: 11px;
        list-style-type: decimal;
        text-align: start;
        padding: 0px 40px;
    }

    
    .splash .heading {
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 25px !important;
        margin-right: 30%;
        line-height: 0px !important;
        letter-spacing: -1px !important;
        color: #11142D !important;
        margin-top: 15% !important;
    }
}

@media only screen and (min-width:1000px) {
    .splash .MuiList-root {
        width: 100% !important;
    }

    .splash .MuiList-root {
        width: 100% !important;
        margin: auto;
        position: relative;
        background-color: #fff;
        font-size: 11px;
        margin: auto;
        list-style-type: decimal;
        text-align: start;
        padding: 0px 40px;
    }

    .splash .MuiList-root {
        width: 100%;
        position: relative;
        background-color: #fff;
        font-size: 11px;
        margin: auto;
        list-style-type: decimal;
        text-align: start;
        padding: 0px 40px;
    }

    .splash .heading {
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 25px !important;
        margin-right: 30%;
        line-height: 0px !important;
        letter-spacing: -1px !important;
        color: #11142D !important;
        font-family: "Montserrat" !important;
        margin-top: 15% !important;
    }
}

.splash .MuiCard-root {
    overflow: auto !important;
}

.login-main {
    min-height: 10rem;
    margin: auto;
    width: 100%;
    padding: 100px .5rem;
}

.login-inner {
    color: white;
    font-size: 1.5rem;
    margin: 0 auto;
    max-width: 22%;
    padding: 0;
    text-align: center;
}

.logo-text {
    padding-top: 65px;
    font-size: 40px !important;
    text-align: center;
    margin: auto !important;
    color: #fff;
    font-weight: 700 !important;
    width: 70%;
}
.login .login-route {
  width: 100%;
}

.login .mt-10 {
  margin-top: 10%;
}

.login .login-route .MuiPaper-elevation1 {
  float: right;
  box-shadow: none;
}

.login .login-route .text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #23262F;
  cursor: pointer !important;
  background: none !important;
  text-decoration: none !important;
}

.login .form {
  margin-top: 10px;
}

.login .form .uiTypography-root .MuiTypography-h3 {
  font-weight: bold;
  text-align: start;
}

.login .regform {
  justify-content: center;
}

.login .MuiTypography-h5 {
  font-size: 32px !important;
  font-weight: 700 !important;
  color: #23262F !important;
  font-style: normal !important;
}

.login .MuiTypography-h6 {
  font-size: 14px !important;
  font-weight: 400;
  color: #777E90 !important;
  font-style: normal !important;
}

.login .MuiFormLabel-root {
  font-size: 12px !important;
  font-weight: 501 !important;
  color: #777e90 !important;
  font-style: normal !important;
}

.login .MuiInputBase-input {
  height: 0.5em !important;
}

.login .MuiOutlinedInput-root {
  border-radius: 12px !important;
}

.login .MuiInputLabel-root {
  font-size: 12px !important;
  font-weight: 501 !important;
  color: #777e90 !important;
  font-style: normal !important;
  margin-bottom: 10px !important;
}

.login .MuiTypography-body1 {
  font-size: 12px !important;
}

.enable {
  border-radius: 24px !important;
  background-image: linear-gradient(223.1deg, #6C328B 0%, #EF2B4B 93.2%) !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #FCFCFD !important;
  text-transform: capitalize !important;
}

.disable {
  border-radius: 24px !important;
  background: #92929D !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #FCFCFD !important;
  text-transform: capitalize !important;
}

.login .MuiInputBase-input {
  font-size: 13px !important;
}

.login .MuiInputBase-input:focus {
  font-size: 13px !important;
}

.login .MuiInputBase-input:hover {
  border-color: rgba(230, 232, 236, 1) !important;
}

.login .MuiSvgIcon-root {
  font-size: 0.9rem !important;
}

.login .typo {
  width: 100% !important;
  margin-top: 46px !important;
  margin-bottom: 10px !important;
  align-self: end;
}

.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.form-container {
  margin: auto;
}

.forgot-text {
  font-size: 12px !important;
  text-align: end !important;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: #6C328B !important;
  background: none !important;
  text-decoration: none !important;
}

.login .user-form {
  justify-content: center;
}

.login .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(230, 232, 236, 1);
}

.login .MuiOutlinedInput-notchedOutline {
  border: 2px solid rgba(230, 232, 236, 1) !important;
}

.login .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #f44336 !important;
}

.login .MuiInputBase-input::-moz-placeholder {
  font-style: normal !important;
  font-weight: 501 !important;
  font-size: 14px !important;
  color: #777e90 !important;
}

.login .MuiInputBase-input::placeholder {
  font-style: normal !important;
  font-weight: 501 !important;
  font-size: 14px !important;
  color: #777e90 !important;
}

.login .forgot-content {
  text-align: end;
}

.login .MuiFormHelperText-root.Mui-error {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  color: #FF0000 !important;
}
.category {
height: 100vh !important;
width: 100vw !important;
}

.leftButton {
  padding: 12px;
  text-align: center;
}
.leftButton:hover {
  background-color: transparent;
}
.wrapper {
  position: absolute;
  left: 4px;
  right: 4px;
  top: 4px;
  display: -ms-flexbox;
  /* display: flex; */
  align-items: flex-end;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  bottom: 3px;
  border-radius: 9px;
}

.wrapper-background {
  background: rgba(0, 0, 0, 0.7);
}

.content {
  flex-grow: 1;
  padding: 12px 16px;
  color: #fff;
  overflow: hidden;
  padding-right: 0;
  position: absolute;
  bottom: 0;
}

.mainWrap {
  position: relative;
  padding: 3px;
  height: 150;
  width: 150;
  box-shadow: none !important;
}

.sub-content {
  font-weight: bold;
}

.img {
  border-radius: 9px;
}

.css-pdwytc-MuiPaper-root {
  border: 4px solid #fff;
  outline: 2px solid black;

  /* border-image-source: linear-gradient(180deg, #ef2a4c 0%, #6c318a 100%); */
}

.onbaording-selected-image {
  border-radius: 6px;
  margin: 0px;
  padding: 2px;
  background: linear-gradient(180deg, #ef2a4c 0%, #6c318a 100%);

}

.category .MuiAppBar-colorPrimary {
  color: black;
  background-color: #fff;
}

.category .MuiPaper-elevation4 {
  box-shadow: none !important;
}

.category .wrapper .check {
  position: absolute;
  z-index: 1111;
  top: 34%;
  left: 40%
}

.category .wrapper .check-hide {
  display: none;
}
.category .MuiToolbar-gutters {
  padding-left: 1px !important;
  padding-right: 20px !important;
}
.category .MuiTypography-h6 {
  font-family: 'Montserrat' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  letter-spacing: -1px;
  color: #11142D !important;
  text-align: center !important;
  padding: 0px 35px !important;
}
.category .wrapper .check-circle-icon {
  font-size: 40px !important;
  color: #fff !important;
}
.category .arrow-icon{
  color: #1E1F20;
  margin-top: -25px;
  margin-left: -60px;
}
.category .skip-btn{
  font-family: 'Montserrat';
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 24px;
  color: #F42074 !important;
  text-transform: capitalize !important;
  margin: auto;
  display: flex;
}
.category .proceed-content{ justify-content: center !important;width: 300px !important;}

.category .proceed-content .proceed-btn-disabled {
  border-radius: 20px !important;
  background: #D7DBE5 !important;
  font-family: 'Montserrat' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  height: 50px !important;
  font-size: 16px !important;
  text-align: center;
  color: #000000 !important;
  text-transform: capitalize !important;
}

.category .proceed-content .proceed-btn {
  border-radius: 20px !important;
  background: linear-gradient(270deg, #6C328B 0%, #F42074 44.66%, #EF2B4B 100%) !important;
  font-family: 'Montserrat' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  height: 50px !important;
  text-align: center;
  color: #FCFCFD !important;
  text-transform: capitalize !important;
}
@media  only screen and (max-width:1200px) {
  .category .tiles-container {
    justify-content: start !important;
  }
}
@media only screen and (max-width:1024px){
  .category .tiles-container {
    justify-content: center !important;
  }
}
@media  only screen and (max-width:900px) {
  .category .tiles-container {
    justify-content: start !important;
  }
}
.expert {
    height: 100vh !important;
    width: 100% !important;
    box-shadow: none !important;
}

.expert .wrapper {
    position: absolute;
    left: 4px;
    right: 4px;
    display: -ms-flexbox;
    /* display: flex; */
    align-items: flex-end;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    bottom: 3px;
    border-radius: 9px;
}


.expert .content {
    flex-grow: 1;
    padding: 12px 16px;
    color: #fff;
    overflow: hidden;
    padding-right: 0;
    position: absolute;
    bottom: 0;
}

.expert .mainWrap {
    position: relative;
    padding: 3px;
    height: 148;
    width: 148;
    box-shadow: none !important;
}

.sub-content {
    font-weight: bold;
}

.img {
    border-radius: 9px;
}

.css-pdwytc-MuiPaper-root {
    border: 4px solid #fff;
    outline: 2px solid black;

    /* border-image-source: linear-gradient(180deg, #ef2a4c 0%, #6c318a 100%); */
}

.expert .onbaording-selected-image {
    border-radius: 6px;
    margin: 0px;
    padding: 2px;
    background: linear-gradient(180deg, #ef2a4c 0%, #6c318a 100%);

}

.expert .MuiAppBar-colorPrimary {
    color: black;
    background-color: #fff;
}

.expert .MuiPaper-elevation4 {
    box-shadow: none !important;
}

.expert .wrapper .check {
    position: absolute;
    z-index: 1111;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.expert .wrapper .check-hide {
    display: none;
}

.expert .MuiToolbar-gutters {
    padding-left: 1px !important;
    padding-right: 20px !important;
}

.expert .my-headings {
font-family: 'Montserrat' !important;
font-style: normal !important;
font-weight: 600 !important;
font-size: 48px !important;
line-height: 72px;
letter-spacing: -1px;
color: #11142D !important;
}

.expert .wrapper .check-circle-icon {
    font-size: 40px !important;
    color: #fff !important;
}

.expert .arrow-icon {
    color: #1E1F20;
}

.expert .skip-btn {
font-family: 'Montserrat';
font-style: normal !important;
font-weight: 500 !important;
font-size: 20px !important;
line-height: 24px;
color: #292A34 !important;
text-transform: capitalize !important;
}

.expert .proceed-content {
    justify-content: center !important;
    width: 300px !important;
}

.expert .proceed-content .proceed-btn-disabled {
    border-radius: 20px !important;
    background: #D7DBE5 !important;
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    text-align: center;
    color: #000000 !important;
    text-transform: capitalize !important;
}

.expert .proceed-content .proceed-btn {
    border-radius: 20px !important;
    background: #70308A !important;
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    text-align: center;
    color: #FCFCFD !important;
    text-transform: capitalize !important;
}

@media only screen and (max-width:1200px) {
    .expert .tiles-container {
        justify-content: start !important;
    }
}

@media only screen and (max-width:600px) {
    .expert .tiles-container {
        justify-content: center !important;
    }
}
@media only screen and (max-width:960px) {
    .expert .tiles-container {
        justify-content: center !important;
    }
}
.expert .custom-card {
    max-width: 100%;
    height: 400px;
    margin-bottom: 2px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    border-radius: 20px !important;
}

.expert .custom-card .title {
    text-align: center;
    font-size: 1.2rem !important;
    font-weight: bold !important;
    color: rgb(40, 41, 41);
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.expert .custom-card .sub-title {
    font-size: 0.8rem !important;
    text-align: center;
    color: #686E6C !important;
    font-weight: 500 !important;
}

.expert .custom-card .sub-text {
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 0.8rem !important;
    text-align: center;
    color: #686E6C !important;
    font-weight: 500 !important;
}
.expert .card-wrapper {
position: relative;
padding: 3px 3px 0px 3px;
height: 390px !important;
}
/* .expert .selected-image {
border-radius: 20px;
margin: 0px;
padding: 2px 2px 1px 2px;
background: linear-gradient(180deg, #ef2a4c 0%, #6c318a 100%);
} */
.expert .selected-image {
box-sizing: border-box;
margin: auto;
position: relative;
background: linear-gradient(180deg, #ef2a4c 0%, #6c318a 100%);
background-clip: border-box;
padding: 2px 2px 43.4px 2px;
border-radius: 20px;
border: 1px solid transparent;
background-clip: padding-box;
bottom: 0;
max-width: 100%;
height: 418px;
}


.expert .card-media {
border-top-left-radius: 20px;
border-top-right-radius: 20px;
}
.expert .wrapper .check-circle-icon {
    font-size: 75px !important;
    color: #6c318a !important;
}
.expert .pl-45 {
    padding-left: 45px;
}
.expert .no-selected-image {
    box-sizing: border-box;
    margin: auto;
    position: relative;
    background-clip: border-box;
    background-clip: border-box;
    padding: 2px 2px 0.4px 2px;
    border-radius: 20px;
    border: 1px solid transparent;
    background-clip: padding-box;
    bottom: 0;
    max-width: 100%;
    height: 440px;
}
.expert .no-selected-image2 {
    box-sizing: border-box;
    margin: auto;
    position: relative;
    background-clip: border-box;
    background-clip: border-box;
    padding: 2px 2px 0.4px 2px;
    border-radius: 20px;
    border: 1px solid transparent;
    background-clip: padding-box;
    bottom: 0;
    max-width: 100%;
    height: 424px;
}

@media only screen and (min-width:960px) {
    .expert .MuiGrid-grid-md-3 {
        flex-grow: 0;
        /* max-width: 20% !important; */
        flex-basis: 25%;
    } 
}
@media only screen and (min-width:600px) {
    .expert .MuiGrid-grid-md-3 {
        flex-grow: 0;
        /* max-width: 90% !important; */
        flex-basis: 25%;
    } 
}
@media only screen and (min-width:1200px) {
    .expert .MuiGrid-grid-md-3 {
        flex-grow: 0;
        /* max-width: 16.5% !important; */
        flex-basis: 25%;
    }   
}
.expert .MuiFormControl-fullWidth {
    width: 300px !important;
}
.expert .selected-image2 {
        /* padding: 2px 2px 8px 2px; */
        padding: 3px 5px 7px 5px;
        border: 2px solid transparent;
        background: white;
        border-radius: 18px;
        height: 411px;
}
.expert .MuiInputAdornment-root {
font-family: 'Montserrat';
font-style: normal;
font-weight: 501;
font-size: 14px;
line-height: 24px;
color: #777E90;
}

.expert .MuiInputBase-input::-moz-placeholder {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 501 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    color: #777E90 !important;
}

.expert .MuiInputBase-input::placeholder {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 501 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    color: #777E90 !important;
}
.video {
    padding: 0px 20px;
}
.video .file-container {
    border-radius: 8px;
    height: 212px;
    border: 1px dashed #979797;
    justify-content: center;
    margin-top: 15px;
}

.video .file-content {
    height: 55px;
    margin-top: 2%;
    flex-basis: 0 !important;
}

.video .file-content .upload-btn {
    background: rgba(219, 219, 219, 0.57075) !important;
    border-radius: 12px;
    height: 73px;
    width: 73px;
    box-shadow: none !important;
}

.video .file-content .MuiAvatar-root {
    width: 30px !important;
    height: 70px !important;
}

.video .file-content .MuiAvatar-img {
    width: 45px;
    height: 30px;
}

.video .file-container .drag-text {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    color: #11142D;

}

.video .file-container .drag-text .drag-browse {
    color: rgba(122, 48, 137, 1);
    font-weight: 700;

}

.video .drag-subtext {
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    color: #11142D;
    opacity: 0.5;
    margin: 0px;
    text-align: center;


}

.video .or {
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 39px;
    color: #000000;
    margin: 10px;
    text-align: center;
}

.video .or-container {
    justify-content: center;
}

.video .select-image {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
    margin: 0;
}

.video .btn-thumbnail {
    font-style: normal !important;
    text-decoration: none !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 16px !important;
    text-align: center !important;
    letter-spacing: 0.857143px !important;
    color: #FFFFFF !important;
    background: #FF8B3F !important;
    text-transform: capitalize !important;
    height: 50px !important;
    border-radius: 18px !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2) !important;
    margin-top: 5% !important;
}

.video .custom-card .MuiPaper-elevation1 {
    box-shadow: none !important;
}

.video .custom-card-preview .MuiPaper-elevation1 {
    box-shadow: none !important;
}

.video .link-heading {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 24px !important;
    line-height: 36px !important;
    color: #1A1A1A !important;
    margin: 0 !important;
}

.video .link-subheading {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    color: #1A1A1A !important;
    margin: 0 !important;
}

.video .subheading-content {
    height: 25px !important;
    padding: 2px 30px 40px 30px !important;
}

.video .linkheading-content {
    padding: 30px !important;
    height: 30px !important;
}

.video .inputlink-content {
    margin-left: 30px !important;
    margin-bottom: 30px !important;
}

.video .basic {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #11142D;
    margin-left: 10px;
}

.video .input-link .MuiFormLabel-root {

    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: rgba(63, 82, 109, 0.491668) !important;
}

.video .input-link .MuiInput-underline {
    border: none !important;
    border-radius: 4px;
}

.video .input-link .MuiInput-underline::after {
    border-bottom: none !important;
}

.video .library-content .library-heading {

    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
    text-align: center;
}

.video .library-btn {
    background: rgba(219, 219, 219, 0.57075) !important;
    border-radius: 12px !important;
    width: 73px !important;
    height: 73px !important;
}

.video .vtitle-field .MuiInputBase-input::-moz-placeholder {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 24px !important;
    /* line-height: 36px !important; */
    color: rgba(63, 82, 109, 0.49) !important;
}

.video .vtitle-field .MuiInputBase-input::placeholder {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 24px !important;
    /* line-height: 36px !important; */
    color: rgba(63, 82, 109, 0.49) !important;
}

.video .wrapper {
    position: absolute;
    left: 4px;
    right: 4px;
    top: 4px;
    display: -ms-flexbox;
    /* display: flex; */
    align-items: flex-end;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    bottom: 3px;
    border-radius: 9px;
}

.video .wrapper-background {
    background: rgba(0, 0, 0, 0.7);
}

.video .wrapper .check {
    position: absolute;
    z-index: 1111;
    top: 34%;
    left: 40%
}

.video .wrapper .check-hide {
    display: none;
}

.video .file-name {

    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    color: #000000;
    text-align: center;
    margin: 0;
    text-overflow: clip;
    width: 197px;
    overflow: hidden;
    white-space: nowrap;
}

.video .object-desc .MuiInputBase-input::-moz-placeholder {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 24px !important;
    /* line-height: 36px !important; */
    color: rgba(63, 82, 109, 0.49) !important;
}

.video .object-desc .MuiInputBase-input::placeholder {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 24px !important;
    /* line-height: 36px !important; */
    color: rgba(63, 82, 109, 0.49) !important;
}

.video .custom-card-preview {
    background: #F9F9F9;
    border-radius: 8px;
    height: 100%;
}

.video .checked-content {
    position: absolute;
    top: 40%;
    right: 45%;
}

.video .card-header {
    height: 30px !important;
    text-align: end !important;
}

@media only screen and (max-width:600px) {
    .video .select-img {
        width: 200px;
        height: 200px;
        border: 0.5px solid rgba(0, 0, 0, 0.101644);
        box-sizing: border-box;
    }

    .video .custom-card {
        background: #F9F9F9;
        border: 1px solid rgba(88, 88, 88, 0.0995684);
        border-radius: 8px;
        height: 100%;
    }

    .video .select-img-preview {
        width: 150px;
        height: 150px;
        border: 0.5px solid rgba(0, 0, 0, 0.101644);
        box-sizing: border-box;
    }

    .video .file-container .drag-text {
        font-size: 17px;
    }

    .video .drag-subtext {
        font-size: 15px;
    }

    .video .select-phases {
        width: 310px !important;
    }

    .video .input-link .MuiInputBase-input {
        width: 100% !important;
    }

    .video .btn-thumbnail {
        width: 290px !important;
    }
}

@media only screen and (min-width:1000px) {
    .video .select-img {
        width: 200px;
        height: 200px;
        border: 0.5px solid rgba(0, 0, 0, 0.101644);
        box-sizing: border-box;
    }

    .video .select-img-preview {
        width: 150px;
        height: 150px;
        border: 0.5px solid rgba(0, 0, 0, 0.101644);
        box-sizing: border-box;
    }

    .video .custom-card {
        background: #F9F9F9;
        border: 1px solid rgba(88, 88, 88, 0.0995684);
        border-radius: 8px;
        height: 100%;
    }

    .video .file-container .drag-text {
        font-size: 24px;
    }

    .video .drag-subtext {
        font-size: 16px;
    }

    .video .select-phases .MuiSelect-select {
        width: 625px !important;
    }

    .video .input-link .MuiInputBase-input {
        width: 370px !important;
    }

    .video .btn-thumbnail {
        width: 328px !important;
    }
}

.invalid-feedback {
    display: block;
    color: #dc3545;
    margin-top: 0.25rem;
    width: 100%;
}
.MuiDialog-paperWidthSm {
  width: 890px;
}

.Edit_modal {
  z-index: -1;
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
}
.Enter_Title .MuiOutlinedInput-input {
  height: 45px;
}

.Enter_Title .MuiOutlinedInput-root {
  border-radius: 10px;
}
.Enter_Description .MuiOutlinedInput-input {
  height: 140px;
}
.Enter_Description .MuiOutlinedInput-root {
  border-radius: 10px;
}

.Edit_dialog .MuiDialog-paperScrollPaper{
  margin-right: 44%;
  margin-top: 12%;
  position: relative;
  width: 225px;
  border-radius: 15px;
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 2px rgba(0,0,0,0.14), 0px 0px 10px 0px rgba(0,0,0,0.12);
}

.inputRounded .MuiOutlinedInput-root {
  border-radius: 20px;
}

.Switch .MuiSwitch-colorSecondary.Mui-checked {
  color: #6c328e;
}

.Switch .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
  background-color: #c1b1cf;
}

.inputRounded .MuiOutlinedInput-root {
  border-radius: 20px;
}

.Switch .MuiSwitch-colorSecondary.Mui-checked {
  color: #6C328E;
}

.Switch .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
  background-color: #C1B1CF;
}

.inputField .MuiOutlinedInput-root {
  border-radius: 15px;
}

.video .file-container {
  border-radius: 8px;
  height: 212px;
  border: 1px dashed #979797;
  justify-content: center;
  margin-top: 15px;
}

.video .file-content {
  height: 55px;
  margin-top: 2%;
  flex-basis: 0 !important;
}

.video .file-content .upload-btn {
  background: rgba(219, 219, 219, 0.57075) !important;
  border-radius: 12px;
  height: 73px;
  width: 73px;
  box-shadow: none !important;
}

.upload-btn1 {
  width: 100%;
  height: 208px;
  box-shadow: none;
}

.video .file-content .MuiAvatar-root {
  width: 30px !important;
  height: 70px !important;
}

.video .file-content .MuiAvatar-img {
  width: 45px;
  height: 30px;
}

.video .file-container .drag-text {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  color: #11142d;
  /* font-family: 'Montserrat'; */
}

.video .file-container .drag-text .drag-browse {
  color: rgba(122, 48, 137, 1);
  font-weight: 700;
}

.video .drag-subtext {
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: #11142d;
  opacity: 0.5;
  margin: 0px;
  /* text-align: center; */
  /* font-family: 'Montserrat'; */
}

.video .or {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 39px;
  color: #000000;
  text-align: center;
  margin: 10px;
  /* text-align: center; */
  /* font-family: 'Montserrat'; */
}

.video .or-container {
  justify-content: center;
}

.video .select-image {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  /* font-family: 'Montserrat'; */
  color: #000000;
  margin: 0;
}

.video .btn-thumbnail {
  /* width: 200px !important; */
  font-style: normal !important;
  text-decoration: none !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 16px !important;
  text-align: center !important;
  letter-spacing: 0.857143px !important;
  color: #ffffff !important;
  background: #653889 !important;
  text-transform: capitalize !important;
  /* font-family: 'Montserrat' !important; */
  height: 56px !important;
  border-radius: 18px !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2) !important;
  margin-top: 5% !important;
}

.video .custom-card .MuiPaper-elevation1 {
  box-shadow: none !important;
}

.video .custom-card-preview .MuiPaper-elevation1 {
  box-shadow: none !important;
}

.video .link-heading {
  /* font-family: 'Montserrat' !important; */
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  color: #1a1a1a !important;
  margin: 0 !important;
}

.video .link-subheading {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  color: #1a1a1a !important;
  margin: 0 !important;
}

.video .subheading-content {
  height: 25px !important;
  padding: 2px 30px 40px 30px !important;
}

.video .linkheading-content {
  padding: 30px !important;
  height: 30px !important;
}

.video .inputlink-content {
  margin-left: 30px !important;
  margin-bottom: 30px !important;
}

.video .basic {
  /* font-family: 'Montserrat' !important; */
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #11142d;
  margin-left: 10px;
}

.video .input-link .MuiFormLabel-root {
  /* font-family: 'Montserrat'; */
  line-height: 1;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  /* line-height: 24px !important; */
  color: rgba(63, 82, 109, 0.491668) !important;
}

.video .input-link .MuiInput-underline {
  border: none !important;
  border-radius: 4px;
}

.video .input-link .MuiInput-underline::after {
  border-bottom: none !important;
}

.video .library-content .library-heading {
  /* font-family: 'Montserrat'; */
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  text-align: center;
}

.video .library-btn {
  background: rgba(219, 219, 219, 0.57075) !important;
  border-radius: 12px !important;
  width: 73px !important;
  height: 73px !important;
}

.video .vtitle-field .MuiInputBase-input::-moz-placeholder {
  /* font-family: 'Montserrat' !important; */
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  /* line-height: 36px !important; */
  color: rgba(63, 82, 109, 0.49) !important;
}

.video .vtitle-field .MuiInputBase-input::placeholder {
  /* font-family: 'Montserrat' !important; */
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  /* line-height: 36px !important; */
  color: rgba(63, 82, 109, 0.49) !important;
}

.video .wrapper {
  position: absolute;
  left: 4px;
  right: 4px;
  top: 4px;
  display: -ms-flexbox;
  /* display: flex; */
  align-items: flex-end;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  bottom: 3px;
  border-radius: 9px;
}

.video .wrapper-background {
  background: rgba(0, 0, 0, 0.7);
}

.video .wrapper .check {
  position: absolute;
  z-index: 1111;
  top: 34%;
  left: 40%;
}

.video .wrapper .check-hide {
  display: none;
}

.video .file-name {
  /* font-family: 'Montserrat'; */
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #000000;
  text-align: center;
  margin: 0;
  text-overflow: clip;
  width: 197px;
  overflow: hidden;
  white-space: nowrap;
}

.video .object-desc .MuiInputBase-input::-moz-placeholder {
  /* font-family: 'Montserrat' !important; */
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  /* line-height: 36px !important; */
  color: rgba(63, 82, 109, 0.49) !important;
}

.video .object-desc .MuiInputBase-input::placeholder {
  /* font-family: 'Montserrat' !important; */
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  /* line-height: 36px !important; */
  color: rgba(63, 82, 109, 0.49) !important;
}

.video .custom-card-preview {
  background: #f9f9f9;
  border-radius: 8px;
  height: 100%;
}

.video .checked-content {
  position: absolute;
  top: 40%;
  right: 45%;
}

.video .card-header {
  height: 30px !important;
  text-align: end !important;
}

@media only screen and (max-width: 600px) {
  .video .select-img {
    width: 200px;
    height: 200px;
    border: 0.5px solid rgba(0, 0, 0, 0.101644);
    box-sizing: border-box;
  }

  .video .custom-card {
    background: #f9f9f9;
    border: 1px solid rgba(88, 88, 88, 0.0995684);
    border-radius: 8px;
    height: 100%;
  }

  .video .select-img-preview {
    width: 150px;
    height: 150px;
    border: 0.5px solid rgba(0, 0, 0, 0.101644);
    box-sizing: border-box;
  }

  .video .file-container .drag-text {
    font-size: 17px;
  }

  .video .drag-subtext {
    font-size: 15px;
  }

  .video .select-phases {
    width: 310px !important;
  }

  .video .input-link .MuiInputBase-input {
    width: 100% !important;
  }

  .video .btn-thumbnail {
    width: 290px !important;
  }
}

@media only screen and (min-width: 1000px) {
  .video .select-img {
    width: 200px;
    height: 200px;
    border: 0.5px solid rgba(0, 0, 0, 0.101644);
    box-sizing: border-box;
  }

  .video .select-img-preview {
    width: 150px;
    height: 150px;
    border: 0.5px solid rgba(0, 0, 0, 0.101644);
    box-sizing: border-box;
  }

  .video .custom-card {
    background: #f9f9f9;
    border: 1px solid rgba(88, 88, 88, 0.0995684);
    border-radius: 8px;
    height: 100%;
  }

  .video .file-container .drag-text {
    font-size: 24px;
  }

  .video .drag-subtext {
    font-size: 16px;
  }

  .video .select-phases .MuiSelect-select {
    /* width: 250px !important; */
  }

  .video .input-link .MuiInputBase-input {
    width: 370px !important;
  }
}

.invalid-feedback {
  display: block;
  color: #dc3545;
  margin-top: 0.25rem;
  width: 100%;
}
.selected-item {
  background: rgba(108, 50, 139, 0.15) !important;  
  /* box-shadow: 0px 0px 10px rgba(229, 229, 229, 0.478939); */
  /* border-radius: 10px; */
  border: none !important;
}

.mainContainer .tableContainer .tableColumn-container {
  box-shadow: 0px 0px 10px rgba(229, 229, 229, 0.478939);
  height: 70px;
}

.mainContainer .tableColumn-container .tableColumn-container td{
  border: none;
}

.mainContainer .tableContainer .tableColumn-container .selected-row{
  background: rgba(108, 50, 139, 0.15);
}

#reward-list-table input[type="checkbox"] {
  border: solid 3px black;
  padding: 0px;
  margin: 0px;
  display: none;
}

#reward-list-table  .block {
  display: inline-block;
  min-height: 18px;
  min-width: 18px;
  border: solid 2px #6c328b;
  margin: 4px;
  border-radius: 4px;
}

#reward-list-table  input[type="checkbox"]:checked + .block::after {
  content: "";
  min-width: 12px;
  max-width: 12px;
  top: 0.1px;
  left:7px;
  right: 7px;
  min-height: 12px;
  max-height: 12px;
  border-radius: 2px;
  background: #6c328b;
  position: absolute;
}

#reward-list-table .MuiTableCell-alignLeft{
  text-align: center !important;
}

#reward-list-table .MuiChip-outlined {
  background: #e1e3fb !important;
  mix-blend-mode: normal !important;
  border-radius: 4px !important;
}

#reward-list-table .MuiChip-label {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  text-align: center;
  color: #6C328B;
}

.existing_course .mainContainer {
  background: #fafafa;
  border: 1px solid #e7e7e7;
  border-radius: 20px;
  padding: 30px;
  overflow-x: auto;
}

.existing_course .tableContainer {
  border-collapse: separate;
  border-spacing: 0 15px;
  width: 100%;
}

.existing_course .tableContainer th {
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
  /* color: white; */
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #101010;
}

.existing_course th {
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
  padding: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #101010;
}

.existing_course td {
  text-align: center;
  background: #ffffff;
  border-width: 1px 0px;
  border-style: solid;
  border-color: #fff;
  box-shadow: -6px -6px 25px rgba(230, 230, 230, 0.15),
      6px 6px 25px rgba(229, 229, 229, 0.15);
  padding: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #101010;
  height: 50px;
}

.existing_course td:last-child {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #fff;
  border-left-color: #fff;
}

.existing_course td:first-child {
  border: 0.5px solid #fff;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-right-color: #fff;
}

.selected-item {
  background: rgba(108, 50, 139, 0.15) !important;  
  /* box-shadow: 0px 0px 10px rgba(229, 229, 229, 0.478939); */
  /* border-radius: 10px; */
  border: none !important;
}

.mainContainer .tableContainer .tableColumn-container {
  box-shadow: 0px 0px 10px rgba(229, 229, 229, 0.478939);
  height: 70px;
}

.mainContainer .tableColumn-container .tableColumn-container td{
  border: none;
}

.mainContainer .tableContainer .tableColumn-container .selected-row{
  background: rgba(108, 50, 139, 0.15);
}

#reward-list-table input[type="checkbox"] {
  border: solid 3px black;
  padding: 0px;
  margin: 0px;
  display: none;
}

#reward-list-table  .block {
  display: inline-block;
  min-height: 18px;
  min-width: 18px;
  border: solid 2px #6c328b;
  margin: 4px;
  border-radius: 4px;
}

#reward-list-table  input[type="checkbox"]:checked + .block::after {
  content: "";
  min-width: 12px;
  max-width: 12px;
  top: 0.1px;
  left:7px;
  right: 7px;
  min-height: 12px;
  max-height: 12px;
  border-radius: 2px;
  background: #6c328b;
  position: absolute;
}

#reward-list-table .MuiTableCell-alignLeft{
  text-align: center !important;
}

#reward-list-table .MuiChip-outlined {
  background: #e1e3fb !important;
  mix-blend-mode: normal !important;
  border-radius: 4px !important;
}

#reward-list-table .MuiChip-label {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  text-align: center;
  color: #6C328B;
}

.existing_course .mainContainer {
  background: #fafafa;
  border: 1px solid #e7e7e7;
  border-radius: 20px;
  padding: 30px;
  overflow-x: auto;
}

.existing_course .tableContainer {
  border-collapse: separate;
  border-spacing: 0 15px;
  width: 100%;
}

.existing_course .tableContainer th {
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
  /* color: white; */
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #101010;
}

.existing_course th {
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
  padding: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #101010;
}

.existing_course td {
  text-align: center;
  background: #ffffff;
  border-width: 1px 0px;
  border-style: solid;
  border-color: #fff;
  box-shadow: -6px -6px 25px rgba(230, 230, 230, 0.15),
      6px 6px 25px rgba(229, 229, 229, 0.15);
  padding: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #101010;
  height: 50px;
}

.existing_course td:last-child {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #fff;
  border-left-color: #fff;
}

.existing_course td:first-child {
  border: 0.5px solid #fff;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-right-color: #fff;
}

.first_box,
.second_box{
    padding: 30px;
    background: #f2f2f2;
    border-radius: 15px;
}

.first_box input,
.second_box input{
    border-radius: 15px;
    background: #fff;
    padding: 20px 10px;
    width: 100%;
    border: 1px solid #d0d0d0;
    padding-left: 50px;
font-size: 20px;
}

.search_icon{
    margin-top: 15px;
    position: relative;
}

.search_icon img{
    position: absolute;
top: 50%;
left: 14px;
transform: translate(0%, -50%);
}

.list input{
    width: auto;
    margin-right: 30px;
}

.list_heading, .list_row{
    display: flex;
justify-content: flex-start;
align-items: center;
border-bottom: 1px solid #d9d9d9;
padding-top: 25px;
padding-bottom: 10px;
padding-left: 10px;
}

.list_heading input{
    visibility: hidden;
}

.list_heading p{
    font-size: 17px;
    font-weight: 600;
}

.list_row p{
    font-size: 17px;
}

.second_box .search_icon{
    width: 60%;
}

.second_box  p{
    width: 30%;
    text-align: center;
}

.select_box{
    width: 100%;
    margin-top: 30px;
    margin-bottom: 80px;
    display: flex;
}

.select_box p{
    padding: 20px 50px;
font-size: 18px;
}

.content_select{
    width: 412px;
}

.content_select div{
    border: none !important;
}

.content_select select{
    height: 56px;
    padding-left: 10px;
    font-size: 18px;
    background: #FAFAFA;
border: 1px solid rgba(0, 0, 0, 0.12);
border-radius: 8px;
}

.content_select .MuiNativeSelect-icon{
    right: 10px;
    top: calc(50% - 22px);
    font-size: 45px;
}

.content_select .MuiInput-underline::before{
    display: none
}

.react_calendar{
    margin-top: 25px;
}

.react_calendar .react-calendar{
    width: 350px;
    border:none;
}

.react-calendar .react-calendar__navigation{
    margin: 0;
    border-bottom: 1px solid #dbdbdb;
}

.react-calendar .react-calendar__month-view__weekdays abbr{
    text-decoration: none;
    color: #7b1fa2;
}

.react-calendar .react-calendar__month-view__days__day{
    background: #d2d2d2;
font-weight: bold;
}

.react-calendar .react-calendar__month-view__days__day--neighboringMonth {
    background: #fff;
    border: 1px solid #ccc;
}

.react-calendar .react-calendar__month-view__days button{
    margin: 3px;
    max-width: 44px;
}

.react-calendar .react-calendar__tile--now:enabled{
    background: #EF2B4B;
    color: #fff;
}

.react-calendar .react-calendar__tile--active{
    border-radius: 0px !important;
    font-size: 15px !important;
    background: #EF2B4B !important;
}

.react-calendar .react-calendar__month-view__days__day--weekend{
    background: #6C328B;
    color: #fff;
}


.optional_box{
    margin-top: 15px;
    display: flex;
}

.optional_box p{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

.optional_box p span{
    margin-left: 10px;
    color: #868686;
    font-weight: 500;
}

.notify_users {
    margin-top: 15px;
}

.notify_users p{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

.notify_users .notify_button{
    display: flex;
}

.notify_input {
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
}

.notify_input .notify_field{
    display: flex;
    align-items: center;
}

.notify_input .notify_field input{
    background: #FAFAFA;
    border: 1px solid #DEDEDE;
    border-radius: 18px;
    width: 437px;
    height: 56px;
    font-size: 16px;
    padding: 15px 20px;
}

.notify_input .notify_field p{
  font-size: 20px;
  display: flex;
  margin-left: 15px;
}

.notify_input .notify_field p img{
   width: 25px;
   margin-left: 15px;
  }

  .send{
    width: 100%;
    text-align: end;
    margin-top: -55px;
    margin-bottom: 25px;
  }
.user-management .label-reward {
  margin: 8px;
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #1a1a1a;
}

.user-management .create-user-btn {
  background: #653889 !important;
  border-radius: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  height: 55px !important;
  width: 175px !important;
}

.user-management .send-invite-user-btn {
  color: #653889 !important;
  border-radius: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  background: #ffffff !important;
  text-transform: capitalize !important;
  height: 55px !important;
  width: 190px !important;
  border: 2px solid #653889 !important;
}

.user-management .MuiOutlinedInput-notchedOutline {
  border: 1px solid #d6d6d6 !important;
  border-radius: 18px !important;
}

.user-management .MuiSelect-select:focus {
  background-color: #ffffff;
}

.user-management .MuiSelect-outlined.MuiSelect-outlined {
  color: #1A1A1A;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}

.user-management .filter-label > p {
  color: #222836;
}

.user-management .bulk-action {
  width: 100% !important;
}

.user-management .select-filter .MuiSelect-select:focus {
  background-color: #fff;
}

.user-management .select-filter .MuiInputBase-input {
  height: unset !important;
}

.user-management .search .MuiInputBase-input {
  height: unset !important;
}

.user-management .search .MuiSvgIcon-root {
  font-size: 1.9rem !important;
}

.user-invite .label-reward {
  margin: 8px;
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #1a1a1a;
}

.user-invite .create-user-btn {
  background: #653889 !important;
  border-radius: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  height: 60px !important;
  width: 175px !important;
}

.user-invite .send-invite-user-btn {
  color: #653889 !important;
  border-radius: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  background: #ffffff !important;
  text-transform: capitalize !important;
  height: 60px !important;
  width: 190px !important;
  border: 2px solid #653889 !important;
}

.user-invite .MuiOutlinedInput-notchedOutline {
  border: 1px solid #d6d6d6 !important;
  border-radius: 15px !important;
  /* background: #FAFAFA !important; */
}

.user-invite .bulk-action {
  width: 100% !important;
}

.user-invite .mainContainer {
  background: #fafafa;
  border: 1px solid #e7e7e7;
  border-radius: 20px;
  padding: 30px;
  overflow-x: auto;
}

.user-invite .tableContainer {
  border-collapse: separate;
  border-spacing: 0 15px;
  width: 100%;
}

.user-invite .tableContainer th {
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #101010;
}

.user-invite th {
  text-align: center;
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
  padding: 5px;
  color: #101010;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.user-invite td {
  text-align: center;
  background: #ffffff;
  border-width: 1px 0px;
  border-style: solid;
  border-color: #979797;
  box-shadow: -6px -6px 25px rgba(230, 230, 230, 0.15),
    6px 6px 25px rgba(229, 229, 229, 0.15);
  padding: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #101010;
  height: 50px;
}

.user-invite td:last-child {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #979797;
  border-left-color: #97979700;
}

.user-invite td:first-child {
  border: 0.5px solid #979797;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-right-color: #97979700;
}

.user-invite .select-filter {
  width: 100% !important;
}

.user-invite .select-filter .MuiSelect-selectMenu {
  border-right: 1px solid #e7e7e7 !important;
}

.user-invite .select-filter .MuiInputBase-input {
  height: unset !important;
}

.user-invite .search .MuiInputBase-input {
  height: unset !important;
}

.user-invite .search .MuiSvgIcon-root {
  font-size: 1.9rem !important;
}

.user-invite .input-label {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  color: #2b2e3c !important;
}

.user-invite .user-heading {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 33px !important;
  color: #12142b !important;
}

.user-invite .custom-chip {
  background: #6c328b !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #fafafa !important;
}

.user-invite .payment-btn {
  background: #6c328b !important;
  border-radius: 12px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #ffffff !important;
  height: 50px !important;
  width: 180px !important;
  text-transform: capitalize !important;
}

.user-invite .download-btn {
  border: 1px solid #6c328b !important;
  background: #ffffff !important;
  border-radius: 12px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #6c328b !important;
  height: 50px !important;
  width: 180px !important;
  text-transform: capitalize !important;
}

.user-invite .MuiChip-label {
  color: #ffffff !important;
}

.custom-dialog .MuiListItemText-primary {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  color: #6c328b !important;
}

.custom-dialog .add-product-btn {
  background: #653889 !important;
  border-radius: 18px !important;
  width: 180px !important;
  height: 50px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  margin-right: 25px !important;
}

.custom-dialog .cancel-btn {
  background: #ffffff !important;
  border: 2px solid #6c328b !important;
  border-radius: 18px !important;
  width: 100px !important;
  height: 50px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #6c328b !important;
  text-transform: capitalize !important;
}

.custom-dialog .dashed-border {
  background: #ffffff !important;
  border: 1px dashed #6c328e !important;
  border-radius: 20px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.custom-dialog .dashed-border .drag-text {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #12142b !important;
  text-transform: capitalize;
}

.custom-dialog .outer-border {
  border: 2px solid rgba(108, 50, 142, 0.25) !important;
  transform: rotate(270deg) !important;
}

.custom-dialog .dashed-border .bottom-text {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #6c328e !important;
}

.custom-dialog .csv-text {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #6c328e !important;
}

.custom-dialog .dashed-border .drag-text2 {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #12142b !important;
  text-transform: capitalize;
  display: flex;
}

.custom-dialog .select-filter {
  width: 100% !important;
}

.custom-dialog .select-all {
  width: 100% !important;
}

.custom-dialog .MuiPaper-root {
  background: #ffffff !important;
  box-shadow: -6px -6px 25px rgba(230, 230, 230, 0.25),
    6px 6px 25px rgba(229, 229, 229, 0.25) !important;
  border-radius: 20px !important;
}

.custom-dialog .MuiOutlinedInput-notchedOutline {
  border: 1px solid #d6d6d6 !important;
  border-radius: 15px !important;
}

.custom-dialog .MuiIconButton-root {
  border-radius: 25% !important;
}

.custom-dialog .add-list {
  background: #6c328b !important;
  height: 30px !important;
  width: 30px !important;
  color: #ffffff !important;
}

.custom-dialog .input-label {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  color: #2b2e3c !important;
}

.custom-dialog .input-label-heading {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  color: #2b2e3c !important;
}

.custom-dialog .MuiListItem-root {
  background: #ffffff !important;
  border-radius: 15px !important;
  box-shadow: 2px 5px 2px 2px rgba(230, 230, 230, 0.25),
    1px 2px 1px 12px rgba(230, 230, 230, 0.25),
    1px 2px 4px 2px rgba(0, 0, 0, 0.12) !important;
  margin-bottom: 15px !important;
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.custom-dialog .MuiList-padding {
  padding-top: 12px !important;
  padding-bottom: 12px !;
}

.custom-dialog .MuiListItemIcon-root {
  border-right: 2px solid #e7e7e7 !important;
  border-radius: 15px !important;
  width: 70px !important;
  height: 50px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-top: 2px solid #e7e7e7 !important;
  border-left: 2px solid #e7e7e7 !important;
  border-bottom: 2px solid #e7e7e7 !important;
  color: #dcdcdc !important;
  margin-right: 20px !important;
}

.custom-dialog .user-heading {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 33px !important;
  color: #12142b !important;
}

.custom-dialog .saturday {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #000000 !important;
}

.custom-dialog .selected-date {
  background: #6c328b !important;
  border-radius: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #fafafa !important;
}

.react-calendar__tile--active {
  background: #6c328b !important;
  border-radius: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #fafafa !important;
}

.download-btn {
    border: 1px solid #6C328B !important;
    background: #FFFFFF !important;
    border-radius: 12px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 16px !important;
    text-align: center !important;
    color: #6C328B !important;
    height: 50px !important;
    width: 180px !important;
    text-transform: capitalize !important;
}
.user-send-invite .label-reward {
    margin: 8px;
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #1A1A1A;
}

.user-send-invite .create-user-btn {
    background: #653889 !important;
    border-radius: 18px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
    height: 60px !important;
    width: 175px !important;
}

.user-send-invite .send-invite-user-btn {
    color: #653889 !important;
    border-radius: 18px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    background: #FFFFFF !important;
    text-transform: capitalize !important;
    height: 60px !important;
    width: 190px !important;
    border: 2px solid #653889 !important;
}

.user-send-invite .label-reward {
    margin: 8px;
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    color: #1A1A1A;
}

.user-send-invite .MuiOutlinedInput-notchedOutline {
    border: 1px solid #D6D6D6 !important;
    border-radius: 15px !important;
    /* background: #FAFAFA !important; */
}

.user-send-invite .bulk-action {
    width: 100% !important;
}

.user-send-invite .mainContainer {
    background: #fafafa;
    border: 1px solid #e7e7e7;
    border-radius: 20px;
    padding: 30px;
    overflow-x: auto;
}

.user-send-invite .tableContainer {
    border-collapse: separate;
    border-spacing: 0 15px;
    width: 100%;
}

.user-send-invite .tableContainer th {
    box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
    color: white;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #101010;
}

.user-send-invite th {
    text-align: center;
    box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
    padding: 5px;
    text-align: center;
    color: #101010;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #101010;
}

.user-send-invite td {
    text-align: center;
    background: #ffffff;
    border-width: 1px 0px;
    border-style: solid;
    border-color: #979797;
    box-shadow: -6px -6px 25px rgba(230, 230, 230, 0.15),
        6px 6px 25px rgba(229, 229, 229, 0.15);
    padding: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #101010;
    height: 50px;
}

.user-send-invite td:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid #979797;
    border-left-color: #97979700;
}

.user-send-invite td:first-child {
    border: 0.5px solid #979797;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-right-color: #97979700;
}

.user-send-invite .select-filter {
    width: 100% !important;
}

.user-send-invite .select-filter .MuiSelect-selectMenu {
    border-right: 1px solid #E7E7E7 !important;
}

.user-send-invite .select-filter .MuiInputBase-input {
    height: unset !important;
}

.user-send-invite .search .MuiInputBase-input {
    height: unset !important;
}

.user-send-invite .search .MuiSvgIcon-root {
    font-size: 1.9rem !important;
}

.user-send-invite .input-label {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 27px !important;
    color: #2B2E3C !important;
}

.user-send-invite .input-label-heading {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 27px !important;
    color: #2B2E3C !important;
}

.user-send-invite .user-heading {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 22px !important;
    line-height: 33px !important;
    color: #12142B !important;

}

.user-send-invite .user-box {
    box-shadow: 2px 5px 2px 2px rgba(230, 230, 230, 0.25), 1px 2px 1px 12px rgba(230, 230, 230, 0.25), 1px 2px 4px 2px rgba(0, 0, 0, 0.12) !important;
    border-radius: 20px !important;
    background: #FFFFFF !important;
    padding: 30px;
}

.user-send-invite .select-all {
    width: 100% !important;
}

.user-send-invite .date-label {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 27px !important;
    color: #12142B !important;
}

.user-send-invite .optional-field {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #979797 !important;
}

.user-send-invite .MuiTypography-root {
    color: #6C328B !important;
}

.user-send-invite .MuiPaper-elevation8 {
    background: #FFFFFF !important;
    box-shadow: 2px 5px 2px 2px rgba(230, 230, 230, 0.25), 1px 2px 1px 12px rgba(230, 230, 230, 0.25), 1px 2px 4px 2px rgba(0, 0, 0, 0.12) !important;
    border-radius: 18px !important;
}

.user-send-invite .MuiPickersDay-daySelected:hover {
    background-color: #c4add0 !important;
}

.user-send-invite .MuiPickersDay-day {
    background-color: #c4add0 !important;
}

.custom-dialog .MuiPaper-root {
    background: #FFFFFF !important;
    box-shadow: -6px -6px 25px rgba(230, 230, 230, 0.25), 6px 6px 25px rgba(229, 229, 229, 0.25) !important;
    border-radius: 20px !important;
}

.custom-dialog .MuiOutlinedInput-notchedOutline {
    border: 1px solid #D6D6D6 !important;
    border-radius: 15px !important;
}

.custom-dialog .MuiIconButton-root {
    border-radius: 25% !important;
}

.custom-dialog .add-list {
    background: #6C328B !important;
    height: 30px !important;
    width: 30px !important;
    color: #ffffff !important;
}

.custom-dialog .input-label {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 27px !important;
    color: #2B2E3C !important;
}

.custom-dialog .input-label-heading {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 27px !important;
    color: #2B2E3C !important;
}

.custom-dialog .MuiListItem-root {
    background: #FFFFFF !important;
    border-radius: 15px !important;
    box-shadow: 2px 5px 2px 2px rgba(230, 230, 230, 0.25), 1px 2px 1px 12px rgba(230, 230, 230, 0.25), 1px 2px 4px 2px rgba(0, 0, 0, 0.12) !important;
    margin-bottom: 15px !important;
    padding-left: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.custom-dialog .MuiList-padding {
    padding-top: 12px !important;
    padding-bottom: 12px !;
}

.custom-dialog .MuiListItemIcon-root {
    border-right: 2px solid #E7E7E7 !important;
    border-radius: 15px !important;
    width: 70px !important;
    height: 50px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-top: 2px solid #E7E7E7 !important;
    border-left: 2px solid #E7E7E7 !important;
    border-bottom: 2px solid #E7E7E7 !important;
    color: #DCDCDC !important;
    margin-right: 20px !important;
}

.custom-dialog .MuiListItemText-primary {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 27px !important;
    color: #6C328B !important;
}

.custom-dialog .send-btn {
    background: #653889 !important;
    border-radius: 18px !important;
    width: 100px !important;
    height: 50px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
    margin-right: 25px !important;
}

.custom-dialog .cancel-btn {
    background: #FFFFFF !important;
    border: 2px solid #6C328B !important;
    border-radius: 18px !important;
    width: 100px !important;
    height: 50px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    color: #6C328B !important;
    text-transform: capitalize !important;
}

.custom-dialog .dashed-border {
    background: #FFFFFF !important;
    border: 1px dashed #6C328E !important;
    border-radius: 20px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.custom-dialog .dashed-border .drag-text {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 30px !important;
    color: #12142B !important;
    text-transform: capitalize;
}

.custom-dialog  .outer-border {
    border: 2px solid rgba(108, 50, 142, 0.25) !important;
    transform: rotate(270deg) !important;
}

.custom-dialog .dashed-border .bottom-text {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 30px !important;
    color: #6C328E !important;
}

.custom-dialog .csv-text {

    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #6C328E !important;
}

.custom-dialog .dashed-border .drag-text2 {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 30px !important;
    color: #12142B !important;
    text-transform: capitalize;
    display: flex;

}
#content-role-table .MuiTableCell-paddingCheckbox {
    padding: 16px !important;
}

#content-role-table .MuiTableCell-alignRight {
    text-align: center !important;
    padding: 15px !important;
    flex-direction: column-reverse !important;
}

#content-role-table .MuiChip-outlined {
    background: #eee7f2 !important;
    mix-blend-mode: normal !important;
    border-radius: 4px !important;
}

#content-role-table .MuiChip-label {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 12px;
    text-align: center;
    color: #6C328B;
}

#content-role-table .manage-link {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #6C328B !important;
    text-decoration: underline !important;
}
.MuiTableCell-paddingCheckbox {
    padding: 16px !important;
}

.MuiTableCell-alignRight {
    text-align: center !important;
    padding: 15px !important;
    flex-direction: column-reverse !important;
}

.MuiChip-outlined {
    background: #eee7f2 !important;
    mix-blend-mode: normal !important;
    border-radius: 4px !important;
}

.MuiTableRow-root td:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  
  .MuiTableRow-root td:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

.MuiChip-label {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 12px;
    text-align: center;
    color: #6C328B;
}

.manage-link {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #6C328B !important;
    text-decoration: underline !important;
}

#content-role-table input[type="checkbox"] {
    border: solid 3px black;
    padding: 0px;
    margin: 0px;
    display: none;
  }
  
#content-role-table  .block {
    display: inline-block;
    min-height: 18px;
    min-width: 18px;
    border: solid 2px #6c328b;
    margin: 4px;
    border-radius: 4px;
  }
  
#content-role-table  input[type="checkbox"]:checked + .block::after {
    content: "";
    min-width: 12px;
    max-width: 12px;
    top: -5px;
    left: 7px;
    right: 6px;
    min-height: 12px;
    max-height: 12px;
    border-radius: 2px;
    background: #6c328b;
    position: absolute;
}

#content-role-table .MuiTableCell-alignLeft{
    text-align: center !important;
}
  
.childTab .PrivateTabIndicator-colorSecondary-68 {
  background-color: rgb(239, 233, 233);
}

.childTab1 .Mui-selected {
  background-color: inherit;
}

.inputRounded .MuiOutlinedInput-root {
  border-radius: 20px;
}

.list .MuiInputBase-root  .MuiSelect-icon { top: 3px !important; } 

.list .MuiInputBase-input {height: 1em !important;}

.list .search-outline .search .MuiOutlinedInput-root {max-width: 440px !important;}

.list .MuiInputLabel-root {margin-bottom: 8px;margin-top: 5px;}

.list .MuiInputBase-input {font-size: 13px !important;border: 1px solid #fff !important}

.list .MuiInputBase-input:focus {font-size: 13px !important;}

.list .MuiInputBase-input:hover {border-color:#fff !important;}

.list .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {border-color: #DEDEDE !important;}

.list .MuiOutlinedInput-notchedOutline {border:2px solid #DEDEDE !important;}

.list .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline { border-color: #f44336 !important;}

.list .MuiInputAdornment-positionStart .MuiSvgIcon-root {fill: #DEDEDE !important; font-size: 2.0rem !important;}

.list .course .MuiOutlinedInput-root{border-radius: 0 !important;}

.list  .filter-label .MuiTypography-colorTextSecondary {font-style: normal !important;font-weight: 600 !important;font-size: 13px !important;line-height: 18px !important;letter-spacing: 0.325px !important;color: #58606A !important}

.list .course-btn {background: #6C328B !important;border-radius: 16px !important;height: 56px !important; width: 196px;font-style: normal;font-weight: 600 !important;font-size: 16px !important;line-height: 16px !important;text-align: center !important;color: #FFFFFF !important;text-transform: capitalize !important;margin-left: 30px !important;}

.list .search-outline .MuiOutlinedInput-notchedOutline{border-radius: 18px; border:2px solid #DEDEDE !important;}

.list .box{display:flex;width: 100%;justify-content: flex-end;align-items: center;}

.list .box .select-course .MuiInputBase-input {font-style: normal;font-weight: 600 !important;font-size: 13px !important;line-height: 18px !important;letter-spacing: 0.325px !important;color: #122239 !important;}

.mainHeder_home {
  width: 100%;
  height: 100px;
  box-shadow: 0px 3px 16px rgba(191, 190, 190, 0.350581);
  background-color: #fff;
  padding: 30px;
  border-radius: 15px;
}
.accordion_header {
  width: 160px;
  box-shadow: none;
}

.header_Box {
  background-color: #f3f0f0;
  border-radius: 10px;
  width: 50%;
}

.active {
  background: #fff;
}
.raisedTab {
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}
.noneActive {
  background: #f3f0f0;
}
.user-management .label-reward {
  margin: 8px;
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #1a1a1a;
}

.user-management .create-user-btn {
  background: #653889 !important;
  border-radius: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  height: 55px !important;
  width: 175px !important;
}

.user-management .send-invite-user-btn {
  color: #653889 !important;
  border-radius: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  background: #ffffff !important;
  text-transform: capitalize !important;
  height: 55px !important;
  width: 190px !important;
  border: 2px solid #653889 !important;
}

.user-management .MuiOutlinedInput-notchedOutline {
  border: 1px solid #d6d6d6 !important;
  border-radius: 18px !important;
}

.user-management .MuiSelect-select:focus {
  background-color: #ffffff;
}

.user-management .MuiSelect-outlined.MuiSelect-outlined {
  color: "#1A1A1A";
  font-size: 18px;
  font-weight: 600;
  line-height: "normal";
}

.user-management .filter-label > p {
  color: #222836;
}

.user-management .bulk-action {
  width: 100% !important;
}

.user-management .select-filter .MuiSelect-select:focus {
  background-color: #fff;
}

.user-management .select-filter .MuiInputBase-input {
  height: unset !important;
}

.user-management .search .MuiInputBase-input {
  height: unset !important;
}

.user-management .search .MuiSvgIcon-root {
  font-size: 1.9rem !important;
}

.selected-item {
  background: rgba(108, 50, 139, 0.15) !important;  
  /* box-shadow: 0px 0px 10px rgba(229, 229, 229, 0.478939); */
  /* border-radius: 10px; */
  border: none !important;
}

.mainContainer .tableContainer .tableColumn-container {
  box-shadow: 0px 0px 10px rgba(229, 229, 229, 0.478939);
  height: 70px;
}

.mainContainer .tableColumn-container .tableColumn-container td{
  border: none;
}

.mainContainer .tableContainer .tableColumn-container .selected-row{
  background: rgba(108, 50, 139, 0.15);
}

#content-role-table input[type="checkbox"] {
  border: solid 3px black;
  padding: 0px;
  margin: 0px;
  display: none;
}

#content-role-table  .block {
  display: inline-block;
  min-height: 18px;
  min-width: 19px;
  border: solid 2px #6c328b;
  margin: 4px;
  border-radius: 4px;
}

#content-role-table  input[type="checkbox"]:checked + .block::after {
  content: "";
  min-width: 13px;
  max-width: 13px;
  /* top: 0.1px;
  left:6.5px;
  right: 7px; */
  min-height: 12px;
  max-height: 12px;
  border-radius: 2px;
  background: #6c328b;
  position: absolute;
}

#content-role-table .MuiTableCell-alignLeft{
  text-align: center !important;
}

#content-role-table .MuiChip-outlined {
  background: #e1e3fb !important;
  mix-blend-mode: normal !important;
  border-radius: 4px !important;
}

#content-role-table .MuiChip-label {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  text-align: center;
  color: #6C328B;
}

.existing_course .mainContainer {
  background: #fafafa;
  border: 1px solid #e7e7e7;
  border-radius: 20px;
  padding: 30px;
  overflow-x: auto;
}

.existing_course .tableContainer {
  border-collapse: separate;
  border-spacing: 0 15px;
  width: 100%;
}

.existing_course .tableContainer th {
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
  /* color: white; */
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #101010;
}

.existing_course th {
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
  padding: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #101010;
}

.existing_course td {
  text-align: center;
  background: #ffffff;
  border-width: 1px 0px;
  border-style: solid;
  border-color: #fff;
  box-shadow: -6px -6px 25px rgba(230, 230, 230, 0.15),
      6px 6px 25px rgba(229, 229, 229, 0.15);
  padding: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #101010;
  height: 50px;
}

.existing_course td:last-child {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #fff;
  border-left-color: #fff;
}

.existing_course td:first-child {
  border: 0.5px solid #fff;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-right-color: #fff;
}

.canvas canvas{
    height: 160px !important;
  }

  .canvas-details {
    width: 80% !important;
  }
  .canvas-details canvas{
    height: 300px!important;
  }
