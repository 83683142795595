.user-management .label-reward {
  margin: 8px;
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #1a1a1a;
}

.user-management .create-user-btn {
  background: #653889 !important;
  border-radius: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  height: 55px !important;
  width: 175px !important;
}

.user-management .send-invite-user-btn {
  color: #653889 !important;
  border-radius: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  background: #ffffff !important;
  text-transform: capitalize !important;
  height: 55px !important;
  width: 190px !important;
  border: 2px solid #653889 !important;
}

.user-management .MuiOutlinedInput-notchedOutline {
  border: 1px solid #d6d6d6 !important;
  border-radius: 18px !important;
}

.user-management .MuiSelect-select:focus {
  background-color: #ffffff;
}

.user-management .MuiSelect-outlined.MuiSelect-outlined {
  color: #1A1A1A;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}

.user-management .filter-label > p {
  color: #222836;
}

.user-management .bulk-action {
  width: 100% !important;
}

.user-management .select-filter .MuiSelect-select:focus {
  background-color: #fff;
}

.user-management .select-filter .MuiInputBase-input {
  height: unset !important;
}

.user-management .search .MuiInputBase-input {
  height: unset !important;
}

.user-management .search .MuiSvgIcon-root {
  font-size: 1.9rem !important;
}
