.selected-item {
  background: rgba(108, 50, 139, 0.15) !important;  
  /* box-shadow: 0px 0px 10px rgba(229, 229, 229, 0.478939); */
  /* border-radius: 10px; */
  border: none !important;
}

.mainContainer .tableContainer .tableColumn-container {
  box-shadow: 0px 0px 10px rgba(229, 229, 229, 0.478939);
  height: 70px;
}

.mainContainer .tableColumn-container .tableColumn-container td{
  border: none;
}

.mainContainer .tableContainer .tableColumn-container .selected-row{
  background: rgba(108, 50, 139, 0.15);
}

#content-role-table input[type="checkbox"] {
  border: solid 3px black;
  padding: 0px;
  margin: 0px;
  display: none;
}

#content-role-table  .block {
  display: inline-block;
  min-height: 18px;
  min-width: 19px;
  border: solid 2px #6c328b;
  margin: 4px;
  border-radius: 4px;
}

#content-role-table  input[type="checkbox"]:checked + .block::after {
  content: "";
  min-width: 13px;
  max-width: 13px;
  /* top: 0.1px;
  left:6.5px;
  right: 7px; */
  min-height: 12px;
  max-height: 12px;
  border-radius: 2px;
  background: #6c328b;
  position: absolute;
}

#content-role-table .MuiTableCell-alignLeft{
  text-align: center !important;
}

#content-role-table .MuiChip-outlined {
  background: #e1e3fb !important;
  mix-blend-mode: normal !important;
  border-radius: 4px !important;
}

#content-role-table .MuiChip-label {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  text-align: center;
  color: #6C328B;
}

.existing_course .mainContainer {
  background: #fafafa;
  border: 1px solid #e7e7e7;
  border-radius: 20px;
  padding: 30px;
  overflow-x: auto;
}

.existing_course .tableContainer {
  border-collapse: separate;
  border-spacing: 0 15px;
  width: 100%;
}

.existing_course .tableContainer th {
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
  /* color: white; */
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #101010;
}

.existing_course th {
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
  padding: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #101010;
}

.existing_course td {
  text-align: center;
  background: #ffffff;
  border-width: 1px 0px;
  border-style: solid;
  border-color: #fff;
  box-shadow: -6px -6px 25px rgba(230, 230, 230, 0.15),
      6px 6px 25px rgba(229, 229, 229, 0.15);
  padding: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #101010;
  height: 50px;
}

.existing_course td:last-child {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #fff;
  border-left-color: #fff;
}

.existing_course td:first-child {
  border: 0.5px solid #fff;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-right-color: #fff;
}
