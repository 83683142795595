.forgotPassword .login-route {
  width: 100%;
}

.forgotPassword .mt-10 {
  margin-top: 12%;
}

.forgotPassword .login-route .MuiPaper-elevation1 {
  float: right;
  box-shadow: none;
  font-family: 'Montserrat', sans-serif;
}

.forgotPassword .login-route .text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #23262F;
  cursor: pointer !important;
  background: none !important;
  text-decoration: none !important;
}

.forgotPassword .form {
  margin-top: 20px;
  justify-content: center;
}

.forgotPassword .form .uiTypography-root .MuiTypography-h3 {
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  text-align: start;
}

.forgotPassword .regform {
  justify-content: center;
}

.forgotPassword .MuiTypography-h5 {
  font-size: 32px !important;
  font-weight: 700 !important;
  color: #23262F !important;
  font-style: normal !important;
}

.forgotPassword .MuiTypography-h6 {
  font-size: 14px !important;
  font-weight: 400;
  color: #777E90 !important;
  font-style: normal !important;
}

.forgotPassword .MuiFormLabel-root {
  font-size: 12px !important;
  font-weight: 501 !important;
  color: #777E90 !important;
  font-style: normal !important;
}

.forgotPassword .MuiInputBase-input {
  height: 0.5em !important;
  font-size: 13px !important;
}

.forgotPassword .MuiOutlinedInput-root {
  border-radius: 12px !important;
}

.forgotPassword .MuiInputLabel-root {
  margin-bottom: 8px;
  margin-top: 5px;
}

.forgotPassword .MuiTypography-body1 {
  font-size: 12px !important;
}

.enable {
  border-radius: 24px !important;
  background-image: linear-gradient(223.1deg, #6C328B 0%, #EF2B4B 93.2%) !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #FCFCFD !important;
  text-transform: capitalize !important;
}

.disable {
  border-radius: 24px !important;
  background: #92929D !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #FCFCFD !important;
  text-transform: capitalize !important;
}

.forgotPassword .MuiInputBase-input:focus {
  font-size: 13px !important;
}

.forgotPassword .MuiSvgIcon-root {
  font-size: 0.9rem !important;
}

.forgotPassword .typo {
  width: 100% !important;
  margin-top: 46px !important;
  margin-bottom: 26px !important;
  align-self: end;
}


.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.form-container {
  margin: auto;
}

.forgot-text {
  font-size: 15px !important;
  font-family: 'Montserrat', sans-serif !important;
  text-align: end !important;
  color: #3772FE !important;
  font-weight: 600;
  background: none !important;
}

.forgotPassword .forgot-heading {
  font-weight: 700 !important;
}

.forgotPassword .label-input {
  text-align: start;
}

.forgotPassword .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(230, 232, 236, 1);
}

.forgotPassword .MuiOutlinedInput-notchedOutline {
  border: 2px solid rgba(230, 232, 236, 1) !important;
}

.forgotPassword .MuiInputBase-input:hover {
  border-color: rgba(230, 232, 236, 1) !important;
}

.forgotPassword .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #f44336 !important;
}

.forgotPassword .MuiInputBase-input::placeholder {
  font-style: normal !important;
  font-weight: 501 !important;
  font-size: 14px !important;
  color: #777e90 !important;
}

.forgotPassword .MuiFormHelperText-root.Mui-error {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  color: #FF0000 !important;
  display: flex !important;
  align-items: center !important;
}